import React, { useState, useEffect } from "react";
import "./UpcomingAttractionsFullView.css";
import firebase from "../../firebase/Firebase";
import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

// SEO HELMET
import { Seo } from "../seo/Seo";
import seoData from "../../data/seoData.json";

const UpcomingAttractionsFullView = () => {
  const db = firebase.firestore();
  const [eventList, setventList] = useState([]);

  useEffect(() => {
    db.collection("upcomingEvents")
      .orderBy("date", "desc")
      .get()
      .then((docs) => {
        if (!docs.empty) {
          let allArticles = [];
          docs.forEach(function(doc) {
            const article = {
              id: doc.id,
              ...doc.data(),
            };
            allArticles.push(article);
          });

          setventList(allArticles);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  console.log(eventList);

  const getEvents = () => {
    db.collection("upcomingEvents").onSnapshot((snapshot) => {
      setventList(snapshot.docs.map((doc) => doc.data()));
    });
  };

  const handleSubmit = () => {
    console.log("submit");
  };

  return (
    <>
      {sessionStorage.getItem("lang") === "Mar" ? (
        <Seo
          title={seoData.UpcomingAttractionsFullView.marathi.title}
          description={seoData.UpcomingAttractionsFullView.marathi.description}
          keyword={seoData.UpcomingAttractionsFullView.marathi.keyword}
          cardTitle={seoData.UpcomingAttractionsFullView.marathi.cardTitle}
          cardImage={seoData.UpcomingAttractionsFullView.marathi.cardImage}
          cardDescription={
            seoData.UpcomingAttractionsFullView.marathi.cardDescription
          }
          cardUrl={seoData.UpcomingAttractionsFullView.marathi.cardUrl}
          cardType={seoData.UpcomingAttractionsFullView.marathi.cardType}
        />
      ) : (
        <Seo
          title={seoData.UpcomingAttractionsFullView.english.title}
          description={seoData.UpcomingAttractionsFullView.english.description}
          keyword={seoData.UpcomingAttractionsFullView.english.keyword}
          cardTitle={seoData.UpcomingAttractionsFullView.english.cardTitle}
          cardImage={seoData.UpcomingAttractionsFullView.english.cardImage}
          cardDescription={
            seoData.UpcomingAttractionsFullView.english.cardDescription
          }
          cardUrl={seoData.UpcomingAttractionsFullView.english.cardUrl}
          cardType={seoData.UpcomingAttractionsFullView.english.cardType}
        />
      )}
      <Navbar />
      <div className="">
        <div className="upcoming-event-heading-containers">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <h2 className="upcoming-event-heading">आगामी आकर्षण</h2>
          ) : (
            <h2 className="upcoming-event-heading">Upcoming Events</h2>
          )}
          <hr className="upcoming-event-heading-line" />
        </div>
        <div>
          <div className="event-page-container">
            {eventList && eventList.length > 0 ? (
              eventList.map((data, idx) => {
                let date = data.date
                  .toDate()
                  .toDateString()
                  .split(" ");
                let eventDate = date[2] + " " + date[1] + " " + date[3];
                return (
                  <>
                    {
                      <div className="event-page-inner-container">
                        <img
                          className="mySwiper-subitem-logos"
                          src={data.image}
                        />
                        {sessionStorage.getItem("lang") === "Mar" ? (
                          <h3 className="upcoming-mySwiper-subitem-headings">
                            {data.coursesTitleMr}
                          </h3>
                        ) : (
                          <h3 className="upcoming-mySwiper-subitem-headings">
                            {data.courseTitle}
                          </h3>
                        )}
                        {sessionStorage.getItem("lang") === "Mar" ? (
                          <p className="upcoming-attractions-dates">
                            {eventDate} पासून सुरु
                          </p>
                        ) : (
                          <p className="upcoming-attractions-dates">
                            Starting from {eventDate}
                          </p>
                        )}

                        <div className="upcoming-attractions-btn-wrapper">
                          {sessionStorage.getItem("lang") === "Mar" ? (
                            <>
                              <Link
                                className="upcoming-attractions-more-info-buttons"
                                to={`/event-View/${data.id}`}
                              >
                                अधिक माहिती
                              </Link>
                              <br />
                            </>
                          ) : (
                            <>
                              <Link
                                className="upcoming-attractions-more-info-buttons"
                                to={`/event-View/${data.id}`}
                              >
                                More Information
                              </Link>
                              <br />
                            </>
                          )}
                          {sessionStorage.getItem("lang") === "Mar" ? (
                            <>
                              <Link
                                to={{
                                  pathname: `/registerform`,
                                  state: {
                                    title: data.coursesTitleMr,
                                  },
                                }}
                              >
                                <button className="upcoming-attractions-register-btns">
                                  रजिस्टर करा
                                </button>
                              </Link>
                              <br />
                            </>
                          ) : (
                            <>
                              <Link
                                to={{
                                  pathname: `/registerform`,
                                  state: {
                                    title: data.courseTitle,
                                  },
                                }}
                              >
                                <button className="upcoming-attractions-register-btns">
                                  Register Now
                                </button>
                              </Link>
                              <br />
                            </>
                          )}
                        </div>
                      </div>
                    }
                  </>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UpcomingAttractionsFullView;
