import React, { useState, useEffect } from "react";
import "./VideoGallery.css";
import { Button } from "@material-ui/core";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import firebase from "../../firebase/Firebase";
import Pagination from "../videoGallery/Pagination";

const VideoGallery = () => {
  const db = firebase.firestore();
  const [videos, setVideos] = useState("");
  const [showPerPage, setShowPerPage] = useState(12);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  useEffect(() => {
    getvideos();
  }, []);

  const getvideos = () => {
    db.collection("videoGallery").onSnapshot((snapshot) => {
      setVideos(snapshot.docs.map((doc) => doc.data()));
    });
  };

  const [model, setModel] = useState(false);
  const [tempvideo, setTempvideo] = useState("");
  const getVideo = (image) => {
    setTempvideo(image);
    setModel(true);
  };

  return (
    <>
    
      <Navbar />
      <div className="video-gallery-container">
        <div className={model ? "model open" : "model"}>
          <video src={tempvideo}></video>
          <Button
            className="video-gallery-button"
            onClick={() => setModel(false)}
          >
            Close
          </Button>
        </div>
        {videos && videos.length > 0 ? (
          <>
            <div className="video-gallery-heading-container">
              <h1 className="video-gallery-heading">Video Gallery</h1>
              <hr className="video-gallery-heading-hr" />
            </div>
            <div>
              <div className="video-gallery-wrapper">
                {videos && videos.length > 0 ? (
                  videos
                    .slice(pagination.start, pagination.end)
                    .map((curr, index) => {
                      return (
                        <div className="video-gallery-inner-wrapper">
                          <iframe
                            className="video-iframe"
                            src={curr.link}
                            frameBorder="0"
                            allow="autoplay;encrypted-media"
                            allowFullScreen
                          ></iframe>
                        </div>
                      );
                    })
                ) : (
                  <></>
                )}
              </div>
              <Pagination
                showPerPage={showPerPage}
                onPaginationChange={onPaginationChange}
                total={videos.length}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </>
  );
};

export default VideoGallery;
