import React, { useState, useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import ContactForm from "../contactForm/ContactForm";
import firebase from "../../firebase/Firebase";
import "./ViewSkill.css";

// SEO HELMET
import { Seo } from "../seo/Seo";

const ViewSkill = () => {
  const db = firebase.firestore();
  const [allSkill, setAllSkill] = useState("");

  useEffect(() => {
    getSkills();
  }, []);

  const getSkills = () => {
    db.collection("skills").onSnapshot((snapshot) => {
      setAllSkill(snapshot.docs.map((doc) => doc.data()));
    });
  };

  return (
    <>
      <Seo
        title="Skills"
        // description={{ __html: allSkill.descriptionEn }}
        // keyword={coursesList.keyword}
      />
      <Navbar />
      <div>
        <div className="view-skill-heading-cont">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <h3 className="view-skill-heading">कौशल्ये</h3>
          ) : (
            <h3 className="view-skill-heading">Skills</h3>
          )}
          <hr className="view-skill-heading-hr" />
        </div>

        {sessionStorage.getItem("lang") === "Mar" ? (
          allSkill && allSkill.length ? (
            allSkill.map((data, idx) => {
              return (
                <div key={idx} className="view-skill-wrapper">
                  <img src={data.logo} className="view-skill-img" />

                  <p className="view-skill-title">{data.headingMr}</p>

                  <p className="view-skill-desc">{data.descriptionMr}</p>
                </div>
              );
            })
          ) : (
            <></>
          )
        ) : allSkill && allSkill.length ? (
          allSkill.map((data, idx) => {
            return (
              <div key={idx} className="view-skill-wrapper">
                <img src={data.logo} className="view-skill-img" />

                <p className="view-skill-title">{data.headingEn}</p>

                <p className="view-skill-desc">{data.descriptionEn}</p>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
      <ContactForm />
      <Footer />
    </>
  );
};

export default ViewSkill;
