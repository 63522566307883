import React from "react";
import { Link } from "react-router-dom";
import "./UpcomingEvents.css";

const UpcomingEvents = () => {
  return (
    <div className="upcoming-event-page">
      <div className="upcoming-event-heading-containers">
        <h2 className="upcoming-event-heading">Upcoming Events</h2>
        <hr className="upcoming-event-heading-line" />
      </div>

      <div className="upcoming-events-cards-container">
        <div className="upcoming-events-card">
          <p className="upcoming-events-heading">
            Chitral Public Speaking And leadership Developement course.
          </p>
          <div className="upcoming-events-date-container">
            <p className="upcoming-events-text">Start Date : </p>
            <p className="upcoming-events-date">15 April 2022</p>
          </div>
          <Link to={`/contact`}>
            <button className="upcoming-events-btn">Enroll Now</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvents;
