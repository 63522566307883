import React, { useState, useEffect } from "react";
import "./SkillsComponent.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation } from "swiper";
import firebase from "../../firebase/Firebase";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SkillsComponent = () => {
  const db = firebase.firestore();
  const [allSkill, setAllSkill] = useState("");
  const [readMore1, setReadMore1] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = (idx) => {
    setOpen(true);
  };

  useEffect(() => {
    getSkills();
  }, []);

  const getSkills = () => {
    db.collection("skills").onSnapshot((snapshot) => {
      setAllSkill(snapshot.docs.map((doc) => doc.data()));
    });
  };

  const readMore = (id, btnState) => {
    if (btnState === "Read more") {
      setReadMore1(id);
    } else {
      setReadMore1(null);
    }
  };

  const readMoreMr = (id, btnState) => {
    if (btnState === "पुढे वाचा") {
      setReadMore1(id);
    } else {
      setReadMore1(null);
    }
  };

  return (
    <>
      <div className="Skills-carousel-container">
        <div className="SkillsComponent-heading-hr-div">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <h2 className="SkillsComponent-heading">कौशल्ये</h2>
          ) : (
            <h2 className="SkillsComponent-heading">Skills</h2>
          )}
          <hr className="SkillsComponent-heading-hr" />
        </div>
        <div>
          <Swiper
            navigation={true}
            slidesPerView={4}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            autoHeight={true}
            spaceBetween={40}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            {allSkill && allSkill.length > 0 ? (
              allSkill.map((data, idx) => {
                return (
                  <>
                    {sessionStorage.getItem("lang") === "Mar" ? (
                      <SwiperSlide className="mySwiper-subitem">
                        <img
                          className="mySwiper-subitem-logo"
                          src={data.logo}
                        />
                        <h3 className="mySwiper-subitem-heading">
                          {data.headingMr}
                        </h3>
                        <p className="mySwiper-subitem-para">
                          {data.descriptionMr}
                        </p>
                        <Link to={`/view-Skill`}>
                          <button className="mySwiper-subitem-btns">
                            पुढे वाचा
                          </button>
                        </Link>
                      </SwiperSlide>
                    ) : (
                      <>
                        <SwiperSlide className="mySwiper-subitem">
                          <img
                            className="mySwiper-subitem-logo"
                            src={data.logo}
                          />
                          <h3 className="mySwiper-subitem-heading">
                            {data.headingEn}
                          </h3>
                          <p className="mySwiper-subitem-para">
                            {data.descriptionEn}
                          </p>

                          <Link to={`/view-Skill`}>
                            <button className="mySwiper-subitem-btns">
                              Read more
                            </button>
                          </Link>
                        </SwiperSlide>
                      </>
                    )}
                  </>
                );
              })
            ) : (
              <></>
            )}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SkillsComponent;
