import "../HomePage.css";
import React from "react";
import Celeb from "../../../assets/HeroSection/Chitralcelebrating.webp";
import SirImage from "../../../assets/HeroSection/HeroImage.webp";
import SliderImg1 from "../../../assets/HeroSection/slideImg1.webp";
import SliderImg2 from "../../../assets/HeroSection/slideImg2.webp";
import SliderImg3 from "../../../assets/HeroSection/slideImg3.webp";
import SliderImg4 from "../../../assets/HeroSection/slideImg4.webp";
function HeroSection() {
  return (
    <div>
      <div className="homepage-top-box-container">
        <section className="homepage-top-box-1">
          <div className="homepage-top-box1-sub-box">
            {sessionStorage.getItem("lang") === "Mar" ? (
              <h1 className="homepage-heading1">
                शुभारंभ वक्तृत्व ! नेतृत्व !! कर्तृत्वाकडे वाटचालीचा
              </h1>
            ) : (
              <h1 className="homepage-heading">
                Realize your full Potential with us
              </h1>
            )}

            {sessionStorage.getItem("lang") === "Mar" ? (
              <p className="homepage-para">
                यशाची गरुडझेप घेऊ इच्छिणाऱ्या स्वप्न पंखांना बळ देणारे हक्काचे
                व्यासपीठ म्हणजे चित्रल
              </p>
            ) : (
              <p className="homepage-para">
                Chitral is the platform of rights that strengthens the dream
                wings of those who want to take the eagle leap of success.
              </p>
            )}

            <a href="#contactform">
              {sessionStorage.getItem("lang") === "Mar" ? (
                <button className="homepage-btn">आमच्यापर्यंत पोहोचा</button>
              ) : (
                <button className="homepage-btn">Reach Us</button>
              )}
            </a>

            <div className="homepage-img-container">
              <img className="homepage-celebrating-img" src={Celeb} />
              <div className="homepage-count-container">
                <p className="homepage-count">18000+</p>
                {sessionStorage.getItem("lang") === "Mar" ? (
                  <p className="homepage-count-para">यशोगाथा तयार केल्या</p>
                ) : (
                  <p className="homepage-count-para">Success Stories Created</p>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="homepage-top-box-2">
          <img src={SirImage} className="homepage-top-box-2-img" />
        </section>
      </div>

      <div
        id="carouselExampleSlidesOnly "
        className="carousel slide image-div"
        data-ride="carousel"
        data-interval="5000"
      >
        <div class="carousel-inner image-div-2">
          <div class="carousel-item active homepage-carousel-image-container">
            <img
              class="d-block homepage-carousel-img "
              src={SliderImg1}
              alt="First slide"
            />
          </div>
          <div class="carousel-item homepage-carousel-image-container">
            <img
              class="d-block homepage-carousel-img"
              src={SliderImg2}
              alt="Second slide"
            />
          </div>
          <div class="carousel-item homepage-carousel-image-container">
            <img
              class="d-block homepage-carousel-img"
              src={SliderImg3}
              alt="Third slide"
            />
          </div>
          <div class="carousel-item homepage-carousel-image-container">
            <img
              class="d-block homepage-carousel-img"
              src={SliderImg4}
              alt="Third slide"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
