import React from "react";
import ChitralLogo from "../../assets/NavBar/chitralLogo.webp";
import ChitralLogoM from "../../assets/NavBar/ChitralM.webp";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const setLangMar = () => {
    if (sessionStorage.getItem("lang") === "Mar") {
      sessionStorage.setItem("lang", "Eng");
      window.location.reload();
    } else {
      sessionStorage.setItem("lang", "Mar");
      window.location.reload();
    }
  };

  return (
    <div>
      <div className="navbar-wrapper1">
        <nav className="navbar navbar-expand-lg navbar-light  p-1.5">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <Link className="logo-link" to="/">
              <img className="navbar-logo" src={ChitralLogoM} />
            </Link>
          ) : (
            <Link className="logo-link" to="/">
              <img className="navbar-logo" src={ChitralLogo} />
            </Link>
          )}

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav navbar-items-container1">
              <li className="nav-item active">
                <Link className="navbar-items " to={`/`}>
                  Home <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(isActive) =>
                    "navbar-items " + (!isActive ? " unselected" : "is-active ")
                  }
                  to={`/about-us`}
                >
                  About Us
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className={(isActive) =>
                    "navbar-items " + (!isActive ? " unselected" : "is-active ")
                  }
                  to={`/event-Page`}
                >
                  Events
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(isActive) =>
                    "navbar-items " + (!isActive ? " unselected" : "is-active ")
                  }
                  to={`/blogs`}
                >
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(isActive) =>
                    "navbar-items " + (!isActive ? " unselected" : "is-active ")
                  }
                  to={`/courses-List`}
                >
                  Courses
                </NavLink>
              </li>
            </ul>

            <div className="navbar-language-and-btn-container1">
              <div className="navbar-languages-wrapper1">
                <i className="far fa-globe navbar-globe-icon2"></i>
                <p className="navbar-items" onClick={setLangMar}>
                  {sessionStorage.getItem("lang") === "Mar"
                    ? "English"
                    : "मराठीत पहा"}
                </p>
              </div>

              <div className="navbar-last-btn-container1">
                {sessionStorage.getItem("lang") === "Mar" ? (
                  <Link to={`/contact`}>
                    <button className="navbar-last-btn">संपर्क साधा</button>
                  </Link>
                ) : (
                  <Link to={`/contact`}>
                    <button className="navbar-last-btn">Contact us</button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
