import React, { useState, useEffect } from "react";
import "./UpcomingAttractions.css";
// import "../skillsComponent/SkillsComponent.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation } from "swiper";
import firebase from "../../firebase/Firebase";
import { Link } from "react-router-dom";

const UpcomingAttractions = () => {
  const db = firebase.firestore();
  const [eventList, setventList] = useState([]);

  useEffect(() => {
    // getSkills();
    db.collection("upcomingEvents")
      .orderBy("date", "desc")
      .get()
      .then((docs) => {
        if (!docs.empty) {
          let allArticles = [];
          docs.forEach(function(doc) {
            const article = {
              id: doc.id,
              ...doc.data(),
            };
            allArticles.push(article);
          });

          setventList(allArticles);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  console.log(eventList);

  const getEvents = () => {
    db.collection("upcomingEvents").onSnapshot((snapshot) => {
      setventList(snapshot.docs.map((doc) => doc.data()));
    });
  };

  const handleSubmit = () => {
    console.log("submit");
  };

  return (
    <>
      <div className="upcoming-carousel-container">
        <div className="upcoming-event-heading-containers">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <h2 className="upcoming-event-heading">आगामी आकर्षण</h2>
          ) : (
            <h2 className="upcoming-event-heading">Upcoming Events</h2>
          )}
          <hr className="upcoming-event-heading-line" />
        </div>
        <div>
          <Swiper
            navigation={true}
            slidesPerView={4}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            autoHeight={true}
            spaceBetween={40}
            modules={[Autoplay, Navigation]}
            className="upcomingmySwiper"
          >
            {eventList && eventList.length > 0 ? (
              eventList.map((data, idx) => {
                let date = data.date
                  .toDate()
                  .toDateString()
                  .split(" ");
                let eventDate = date[2] + " " + date[1] + " " + date[3];
                return (
                  <>
                    {sessionStorage.getItem("lang") === "Mar" ? (
                      <div className="upcoming-flex">
                        <SwiperSlide className="upcoming-mySwiper-subitem">
                          <img
                            className="upcoming-mySwiper-subitem-logo"
                            src={data.image}
                          />
                          <h3 className="upcoming-mySwiper-subitem-heading">
                            {data.coursesTitleMr}
                          </h3>
                          <p className="upcoming-attractions-date">
                            {eventDate} पासून सुरु
                          </p>
                          <div className="button-container">
                            <Link to={`/event-View/${data.id}`}>
                              <button className="upcoming-attractions-more-info-button">
                                अधिक माहिती
                              </button>
                            </Link>
                            <br />
                            <Link
                              to={{
                                pathname: `/registerform`,
                                state: {
                                  title: data.coursesTitleMr,
                                },
                              }}
                            >
                              <button className="upcoming-attractions-register-button">
                                रजिस्टर करा
                              </button>
                            </Link>
                          </div>
                        </SwiperSlide>
                      </div>
                    ) : (
                      <>
                        <div className="upcoming-flex">
                          <SwiperSlide className="upcoming-mySwiper-subitem">
                            <img
                              className="upcoming-mySwiper-subitem-logo"
                              src={data.image}
                            />
                            <h3 className="upcoming-mySwiper-subitem-heading">
                              {data.courseTitle}
                            </h3>
                            <p className="upcoming-attractions-date">
                              {eventDate} पासून सुरु
                            </p>
                            <Link to={`/event-View/${data.id}`}>
                              <button className="upcoming-attractions-more-info-button">
                                More Information
                              </button>
                            </Link>
                            <br />
                            <Link
                              to={{
                                pathname: `/registerform`,
                                state: {
                                  title: data.courseTitle,
                                },
                              }}
                            >
                              <button className="upcoming-attractions-register-button">
                                Register here
                              </button>
                            </Link>
                          </SwiperSlide>
                        </div>
                      </>
                    )}
                  </>
                );
              })
            ) : (
              <></>
            )}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default UpcomingAttractions;
