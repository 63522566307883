import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <div>
      <Navbar />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default Contact;
