import React, { useEffect, useState } from "react";
import "../HomePage.css";
import firebase from "../../../firebase/Firebase";
import { Link } from "react-router-dom";

function GallerSection() {
  const [images, setImages] = useState("");
  const [model, setModel] = useState(false);
  const [tempimage, setTempimage] = useState("");

  const getImg = (image) => {
    setTempimage(image);
    setModel(true);
  };
  const db = firebase.firestore();
  useEffect(() => {
    getImages();
  }, []);
  const getImages = () => {
    db.collection("imageGallery").onSnapshot((snapshot) => {
      setImages(snapshot.docs.map((doc) => doc.data()));
    });
  };
  return (
    <div>
      <div className="image-gallery-container">
        <div>
          <div className={model ? "model open" : "model"}>
            <img src={tempimage} />
            <button
              className="image-gallery-button"
              onClick={() => setModel(false)}
            >
              <i class="fas fa-times cross-icon"></i>
            </button>
          </div>
        </div>

        <div className="homepage-img-heading-cont">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <h3 className="homepage-gallery-heading">रतिमा गॅलरी </h3>
          ) : (
            <h3 className="homepage-gallery-heading">Image gallery</h3>
          )}
          <hr className="homepage-img-heading-line" />
        </div>
        <div>
          <div className="homepage-image-gallery-wrapper">
            {images && images.length > 0 ? (
              images.map((curr, index) => {
                return (
                  <div className="homepage-image-gallery-inner-wrapper">
                    <img
                      className="homepage-image-gallery-images"
                      src={curr.imgLink}
                      key={index}
                      onClick={() => getImg(curr.imgLink)}
                    />
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="image-see-more-cont">
          <Link to={`/image-gallery`}>See More</Link>
          <i class="fas fa-arrow-right arrow-right-icon"></i>
        </div>
      </div>
    </div>
  );
}

export default GallerSection;
