//in ChatBot indexes 2, 3 are changed to 4, 5...
import React, { useState, useEffect } from "react";
import ChatBot from "react-simple-chatbot";
import "./Chatbot.css";
import firebase from "../../firebase/Firebase";
// const timestamp = firebase.firestore.FieldValue.serverTimestamp();
const timestamp = firebase.firestore.Timestamp.fromDate(new Date());

const type = "chatbotform";

function Chatbot() {
  const [opened, setopened] = useState(false);

  useEffect(() => {
    setTimeout(function() {
      setopened(true);
    }, 45000);
  }, []);

  const steps = [
    {
      id: "1",
      message: "नमस्कार",
      trigger: "2",
    },
    {
      id: "2",
      message: "चित्रल वर्ल्ड आपली काय मदत करू शकतो ?",
      trigger: "3",
    },

    {
      id: "3",
      user: true,
      trigger: ({ value, steps }) => {
        return "4";
      },
    },
    {
      id: "4",
      message: "कृपया तुमचे नाव सांगा",
      trigger: "5",
    },

    {
      id: "5",
      user: true,
      trigger: ({ value, steps }) => {
        return "6";
      },
    },
    {
      id: "6",
      message: "कृपया तुमचा ईमेल प्रदान करा",
      trigger: "7",
    },
    {
      id: "7",
      user: true,
      inputAttributes: {
        type: "email",
      },
      trigger: ({ value, steps }) => {
        firebase
          .firestore()
          .collection("ChatBotQueries")
          .add({
            to: steps[7]["message"],
            cc: ["info@chitralworld.com"],
            message: {
              subject: `Chitral Classes | We got it -- RE: ${steps[3]["message"]}`,
              text: ``,
              html: `<div>Hi ${steps[5]["message"]},<br/><br/> Thanks so much for reaching out! This auto-reply is just to let you know…<br/><br/>

We received your email and will get back to you with a (human) response as soon as possible. During 9:00hrs to 18:00hrs that’s usually within a couple of hours. Evenings and weekends may take us a little bit longer.
<br/><br/>
If you have any additional information that you think will help us to assist you, please feel free to reply to this email.</div><br/> <br/>

              
Details that you provided are as follows:
<br/><br/>
Name: ${steps[5]["message"]}
<br/>
EmailId: ${steps[7]["message"]}
<br/>
Query: ${steps[3]["message"]}
<br/>
We look forward to chatting soon!
<br/><br/>
regards,<br/>
Chitral World,<br/>
Ph no: +912040038718,<br/>
Pune.<br/>`,
            },
            // timestamp: timestamp,
            date: firebase.firestore.Timestamp.fromDate(new Date()),

            email: `${steps[7]["message"]}`,
            fullName: `${steps[5]["message"]}`,
            description: `${steps[3]["message"]}`,
          })
          .then((res) => {
            console.log("submitted succesfully", res);
          })
          .catch((err) => {
            console.log("error found while submitting form", err);
          });
        return "8";
      },
    },

    {
      id: "8",
      component: (
        <div style={{ background: "transperent" }}>
          <h4>धन्यवाद!!</h4>
        </div>
      ),
      end: true,
    },
  ];
  const handleFloating = ({ opened }) => {
    setopened(opened);
  };
  return (
    <div className="chatbot-outer-div">
      <ChatBot
        steps={steps}
        opened={opened}
        floating={true}
        toggleFloating={handleFloating}
        headerTitle="चित्रल मध्ये आपले स्वागत आहे"
      />
    </div>
  );
}

export default Chatbot;

// import React, { useState, useEffect } from "react";
// import ChatBot from "react-simple-chatbot";
// import "./Chatbot.css";
// import firebase from "../../firebase/Firebase";
// const timestamp = firebase.firestore.FieldValue.serverTimestamp();
// const type = "chatbotform";

// function Chatbot() {
// const logo = "/assets/img/sclogo.png";

// export default function ChatbotUI() {
//   const [opened, setopened] = useState(false);

//   useEffect(() => {
//     setTimeout(function() {
//       setopened(true);
//     }, 45000);
//   }, []);

//   const steps = [
//     {
//       id: "1",
//       message: "Hi I'm AI Bot",
//       message: "Hi I'm ScroBot",
//       trigger: "2",
//     },
//     {
//       id: "2",
//       message: "Whats your good name?",
//       trigger: "3",
//     },
//     {
//       id: "3",
//       user: true,
//       trigger: ({ value, steps }) => {
//         return "4";
//       },
//     },
//     {
//       id: "4",
//       message: "Hi ! How can we help you?",
//       message: "Hi {previousValue}! How can we help you?",
//       trigger: "5",
//     },
//     {
//       id: "5",
//       user: true,
//       trigger: ({ value, steps }) => {
//         return "6";
//       },
//     },
//     {
//       id: "6",
//       message: "Please provide your mail",
//       trigger: "7",
//     },
//     {
//       id: "7",
//       user: true,
//       inputAttributes: {
//         type: "email",
//       },
//       trigger: ({ value, steps }) => {
//         return "8";
//       },
//     },
//     {
//       id: "8",
//       message: "Please provide your contact number ",
//       trigger: "9",
//     },
//     {
//       id: "9",
//       user: true,
//       trigger: ({ value, steps }) => {
//         firebase
//           .firestore()
//           .collection("ChatBotQueries")
//           .add({
//             to: steps[7]["message"],
//             cc: [
//               "contact@scrobits.com",
//               "digvijay.scrobits@gmail.com",
//               "chitralpune@gmail.com",
//             ],
//             message: {
//               subject: `Chitral Classes | We got it -- RE: ${steps[5]["message"]}`,
//               text: ``,
//               html: `<div>Hi ${steps[3]["message"]},<br/><br/> Thanks so much for reaching out! This auto-reply is just to let you know…<br/><br/>
// We received your email and will get back to you with a (human) response as soon as possible. During 9:00hrs to 18:00hrs that’s usually within a couple of hours. Evenings and weekends may take us a little bit longer.
// <br/><br/>
// If you have any additional information that you think will help us to assist you, please feel free to reply to this email.</div><br/> <br/>
// Details that you provided are as follows:
// <br/><br/>
// Name: ${steps[3]["message"]}
// <br/>
// EmailId: ${steps[7]["message"]}
// <br/>
// Query: ${steps[5]["message"]}
// <br/>
// We look forward to chatting soon!
// <br/><br/>
// regards,<br/>
// Chitral World,<br/>
// Ph no: +912040038718,<br/>
// Pune.<br/>`,
//             },
//               "arpit@scrobits.com",
//               "digvijay.scrobits@gmail.com",
//             ],
//             message: {
//               subject: `ScroBits | We got it -- RE: ${steps[5]["message"]}`,
//               text: ``,
//               html: `Hi ${steps[3]["message"]},<br/> <br/> Thanks so much for reaching out! This auto-reply is just to let you know…<br/> <br/> We received your email and will get back to you with a (human) response as soon as possible. During 9:00hrs to 18:00hrs that’s usually within a couple of hours. Evenings and weekends may take us a little bit longer.<br/> <br/> If you have any additional information that you think will help us to assist you, please feel free to reply to this email.<br/> <br/> Details that you provided are as follows:<br/> Name:  ${steps[3]["message"]}<br/> EmailId: ${steps[7]["message"]}<br/> Phone:  ${steps[9]["message"]}<br/> Query:   ${steps[5]["message"]}<br/> <br/> We look forward to chatting soon!<br/> <br/> Cheers,<br/> ScroBits Technologies, <br/> Ph no: 9607020806,<br/> Pune.<br/> `,
//             },

//             emailId: steps[7]["message"],
//             description: steps[5]["message"],
//             fullName: steps[3]["message"],
//             type: type,
//             timestamp: timestamp,
//           })
//           .then((res) => {
//             console.log("submitted succesfully", res);
//           })
//           .catch((err) => {
//             console.log("error found while submitting form", err);
//           });
//         return "8";
//       },
//     },

//     {
//       id: "8",
//       component: (
//         <div style={{ background: "transperent" }}>
//           <h4>Thank you!!</h4>
//             console.log("error found while subkitting form", err);
//           });
//         return "10";
//       },
//     },
//     {
//       id: "10",
//       component: (
//         <div style={{ background: "transperent" }}>
//           <button
//             className="chatbot-form-submit-btn"
//             onClick={() => {
//               window.location.href = "/get-started";
//             }}
//           >
//             Get Started
//           </button>

//           <a
//             href="https://scrobits.notion.site/ScroBits-Technologies-daaaf7ad1c474050ae5ef7a903cf0e22"
//             target="_blank"
//           >
//             <button className="chatbot-form-submit-btn">
//               See Our Portfolio
//             </button>
//           </a>
//         </div>
//       ),
//       end: true,
//     },
//   ];
//   const handleFloating = ({ opened }) => {
//     setopened(opened);
//   };
//   return (
//     <div className="chatbot-outer-div">
//       <ChatBot
//         steps={steps}
//         opened={opened}
//         floating={true}
//         toggleFloating={handleFloating}
//         headerTitle="Welcome to Chitral"
//         headerTitle="Welcome to Scrobits Technologies"
//         botAvatar={logo}
//       />
//     </div>
//   );
// }

// export default Chatbot;
