import React, { useState, useEffect } from "react";
import firebase from "../../../firebase/Firebase";
import { Link } from "react-router-dom";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import "./CoursesList.css";
import ContactUs from "../../contactForm/ContactForm";

// SEO HELMET
import { Seo } from "../../seo/Seo";
import seoData from "../../../data/seoData.json";

const CoursesList = () => {
  const db = firebase.firestore();
  const [coursesList, setCoursesList] = useState([]);

  useEffect(() => {
    db.collection("courses")
      .get()
      .then((docs) => {
        if (!docs.empty) {
          let allArticles = [];
          docs.forEach(function(doc) {
            const article = {
              id: doc.id,
              ...doc.data(),
            };
            allArticles.push(article);
          });

          setCoursesList(allArticles);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const getEvents = () => {
    db.collection("courses").onSnapshot((snapshot) => {
      setCoursesList(snapshot.docs.map((doc) => doc.data()));
    });
  };

  const handleSubmit = () => {
    console.log("submit");
  };
  console.log(coursesList);

  return (
    <>
      {sessionStorage.getItem("lang") === "Mar" ? (
        <Seo
          title={seoData.CoursesList.marathi.title}
          description={seoData.CoursesList.marathi.description}
          keyword={seoData.CoursesList.marathi.keyword}
          cardTitle={seoData.CoursesList.marathi.cardTitle}
          cardImage={seoData.CoursesList.marathi.cardImage}
          cardDescription={seoData.CoursesList.marathi.cardDescription}
          cardUrl={seoData.CoursesList.marathi.cardUrl}
          cardType={seoData.CoursesList.marathi.cardType}
        />
      ) : (
        <Seo
          title={seoData.CoursesList.english.title}
          description={seoData.CoursesList.english.description}
          keyword={seoData.CoursesList.english.keyword}
          cardTitle={seoData.CoursesList.english.cardTitle}
          cardImage={seoData.CoursesList.english.cardImage}
          cardDescription={seoData.CoursesList.english.cardDescription}
          cardUrl={seoData.CoursesList.english.cardUrl}
          cardType={seoData.CoursesList.english.cardType}
        />
      )}
      <Navbar />
      <div className="courses-list-container">
        <div className="course-list-heading-containers">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <h1 className="courses-list-top-heading">सर्व अभ्यासक्रम</h1>
          ) : (
            <h1 className="courses-list-top-heading">All Courses</h1>
          )}
          <hr className="courses-list-hr-tag" />
        </div>

        <div>
          <div className="courses-list-cards-container">
            {coursesList && coursesList.length > 0 ? (
              coursesList.map((data, idx) => {
                return (
                  <>
                    {
                      <div>
                        <div className="courses-list-card-wrapper">
                          <div className="courses-list-card-img-wrapper">
                            <img
                              className="courses-list-card-img"
                              src={data.image}
                              alt="courses image"
                            />
                          </div>
                          {sessionStorage.getItem("lang") === "Mar" ? (
                            <p className="courses-card-heading">
                              {data.titleMr}
                            </p>
                          ) : (
                            <p className="courses-card-heading">{data.title}</p>
                          )}
                          {/* NEW CODE  */}
                          <div className="courses-list-cards-btn-container">
                            {sessionStorage.getItem("lang") === "Mar" ? (
                              <>
                                <Link
                                  className="courses-list-card-btn1"
                                  to={`/course-View/${data.id}`}
                                >
                                  अभ्यासक्रम पहा
                                </Link>
                                <br />
                              </>
                            ) : (
                              <>
                                <Link
                                  className="courses-list-card-btn1"
                                  to={`/course-View/${data.id}`}
                                >
                                  View Courses
                                </Link>
                                <br />
                              </>
                            )}
                            {sessionStorage.getItem("lang") === "Mar" ? (
                              <Link
                                className="courses-list-card-btn2"
                                to={{
                                  pathname: `/registerform`,
                                  state: {
                                    title: data.titleMr,
                                  },
                                }}
                              >
                                खरेदी करा
                              </Link>
                            ) : (
                              <Link
                                className="courses-list-card-btn2"
                                to={{
                                  pathname: `/registerform`,
                                  state: {
                                    title: data.title,
                                  },
                                }}
                              >
                                Buy Now
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  </>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <ContactUs />
      <Footer />
    </>
  );
};

export default CoursesList;
