import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import firebase from "../../firebase/Firebase";
import Snackbar from "@material-ui/core/Snackbar";
import Navbar from "../navbar/Navbar";
import ContactUs from "../contactForm/ContactForm";
import Footer from "../footer/Footer";
import Comment from "../comment/Comment";

import { useLocation } from "react-router-dom";

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./BlogView.css";

// SEO HELMET
import { Seo } from "../seo/Seo";
import Loader from "../loader/Loader";

const BlogView = (props) => {
  const db = firebase.firestore();
  const [blog, setBlog] = useState([]);
  const { id } = useParams();
  const userRef = db.collection("blogs").doc(id);
  const increment = firebase.firestore.FieldValue.increment(1);
  const decrement = firebase.firestore.FieldValue.increment(-1);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    fetch(
      "http://localhost:3000/blog-View/" +
        props.match.params.id +
        props.match.params.title
    );
    loadData();
  }, []);

  const loadData = async () => {
    const docRef = await db.collection("blogs").doc(id);
    docRef
      .onSnapshot((doc) => {
        setBlog(doc.data());
      })
      .catch((e) => console.log(e));
  };

  const handleCopy = (e) => {
    e.preventDefault();
    handleClick();
    return false;
  };

  const handleLike = () => {
    setIsActive(!isActive);
    isActive
      ? userRef.update({ like: decrement })
      : userRef.update({ like: increment });
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (blog.length === 0) {
    return (
      <>
        <Loader />
      </>
    );
  }

  let date = blog.date
    .toDate()
    .toDateString()
    .split(" ");
  let blogDate = date[2] + " " + date[1] + " " + date[3];

  console.log("blog", blog);

  return (
    <>
      {sessionStorage.getItem("lang") === "Mar" ? (
        <Seo
          title={blog.title}
          description={blog.summary}
          keyword={blog.keyword}
          cardTitle={blog.title}
          cardImage="/static/media/ChitralM.47070a5981c86494ad63.webp"
          cardDescription={blog.summary}
          cardUrl="/blog-View/:id"
          cardType="website"
        />
      ) : (
        <Seo
          title={blog.title}
          description={blog.summary}
          keyword={blog.keyword}
          cardTitle={blog.title}
          cardImage="/static/media/ChitralM.47070a5981c86494ad63.webp"
          cardDescription={blog.summary}
          cardUrl="/blog-View/:id"
          cardType="website"
        />
      )}
      <Navbar />

      <div className="blogs-page">
        <Link className="blog-view-page-back-btn" to={`/blogs`}>
          <i className="fas fa-arrow-left blog-view-page-arrow"></i>
          Back
        </Link>
        <Snackbar
          severity="error"
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Copy text is not allowed"
        />
        <div className="blogview-page-blog-container">
          <div className="blogview-blog-thumnNail-wrapper">
            <img className="blogview-blog-thumnNail" src={blog.thumbNail} />
          </div>
          <p className="blogview-blog-heading">{blog.title}</p>
          <div className="blogview-blog-date-and-authorname-cont">
            <p className="blogview-blog-date">{blogDate}</p>
            <p className="blogview-blog-authorname">{blog.authorName}</p>
          </div>
          <div
            className="blogview-blog-content"
            onCopyCapture={(e) => {
              handleCopy(e);
            }}
            dangerouslySetInnerHTML={{ __html: blog.content }}
          />
        </div>
        <div className="blog-view-share-like-btn-cont">
          <section className="blog-view-like-btn-wrapper">
            <i
              onClick={handleLike}
              className={
                isActive
                  ? "fas fa-thumbs-up thumb-icon1 "
                  : " far fa-thumbs-up thumb-icon"
              }
            ></i>
            &nbsp;&nbsp;&nbsp;
            <p className="Like-text">{blog.like} Likes</p>
          </section>
          <section>
            <div class="dropdown ">
              <button
                class="btn share-btn  dropdown-toggl"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Share &nbsp;
                <i class="fas fa-share-alt"></i>
              </button>
              <ul class="dropdown-menu " aria-labelledby="dropdownMenuButton1">
                <FacebookShareButton
                  url={`${window.location.href}`}
                  quote={"Want to know more click here"}
                >
                  <FacebookIcon className="share-button-icon"></FacebookIcon>
                </FacebookShareButton>

                <WhatsappShareButton url={`${window.location.href}`}>
                  <WhatsappIcon className="share-button-icon"></WhatsappIcon>
                </WhatsappShareButton>

                <LinkedinShareButton title="Chitral">
                  <LinkedinIcon className="share-button-icon"></LinkedinIcon>
                </LinkedinShareButton>
                <TwitterShareButton
                  url={`${window.location.href}`}
                  title="Chitral"
                >
                  <TwitterIcon className="share-button-icon"></TwitterIcon>
                </TwitterShareButton>
                <CopyToClipboard text={`${window.location.href}`}>
                  <button className="social-copy-btn">Copy</button>
                </CopyToClipboard>
              </ul>
            </div>
          </section>
        </div>
        <Comment id={id} />
      </div>
      <ContactUs />
      <Footer />
    </>
  );
};

export default BlogView;
