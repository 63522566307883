import React, { useState, useEffect } from "react";
import firebase from "../../firebase/Firebase";
import swal from "sweetalert";
import "./ContactForm.css";
const SITE_KEY = "6LfTzQAgAAAAABEu52FKL_wJEucF-UaeVngqHIlM";

function ContactForm() {
  var db = firebase.firestore();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [phoneNumberAlert, setPhoneNumberAlert] = useState(false);

  const [userState, setUserState] = useState({
    currentUser: {
      fullName: "",
      phoneNumber: "",
      emailId: "",
      city: "",
      message: "",
    },
  });

  useEffect(() => {
    handleRecaptcha();
  }, []);
  const handleRecaptcha = () => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function() {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function() {
        console.log("Script loaded!");
      }
    );
  };
  const handleOnClick = (e) => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token) => {
          submitData(token);
        })
        .catch((err) => {
          console.log("Error", err);
        });
    });
  };

  const submitData = (token) => {
    console.log("fullname", token);
    // call a backend API to verify reCAPTCHA response
    fetch(
      `https://tenuous-insect-production.up.railway.app/api/recapcha/verify`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "g-recaptcha-response": token,
        }),
      }
    )
      .then(async (res) => {
        setLoading(false);
        setResponse(await res.json());
        handleSubmit();
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleSubmit = async () => {
    db.collection("contactFormData")
      .add({
        fullName: userState.currentUser.fullName,
        emailId: userState.currentUser.emailId,
        city: userState.currentUser.city,
        phoneNumber: userState.currentUser.phoneNumber,
        message: userState.currentUser.message,
        date: firebase.firestore.Timestamp.fromDate(new Date()),
      })

      .then(() => {
        swal({
          icon: "success",
          title: "Done",
        });
        setUserState({
          currentUser: {
            fullName: "",
            phoneNumber: "",
            emailId: "",
            city: "",
            message: "",
          },
        });
      })

      .catch((err) => {
        console.log("Error", err);
      });
  };
  const handleChange_ = (e) => {
    if (e.target.name === "fullName") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name === "emailId") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name === "phoneNumber") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
      if (userState.currentUser.phoneNumber.length === 9) {
        setPhoneNumberAlert(false);
      } else {
        setPhoneNumberAlert(true);
      }
    } else if (e.target.name === "city") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name === "message") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  return (
    <>
      <div className="contact-form-page-container">
        <div className="contact-form-heading-container">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <h2 className="contactus-form-heading">आमच्याशी संपर्क साधा</h2>
          ) : (
            <h2 className="contactus-form-heading">Contact us</h2>
          )}
          <hr className="contact-form-heading-hr-tag" />
        </div>

        <div className="contact-form-wrapper">
          <form
            className="contact-us-left-section-form"
            onSubmit={handleOnClick}
          >
            <input
              className="contact-us-left-section-form-inputs"
              name="fullName"
              type="text"
              placeholder="Full Name*"
              value={userState.currentUser.fullName}
              onChange={handleChange_}
              required
            ></input>

            <div className="contact-us-left-section-form-input-container">
              <input
                className="contact-us-left-section-form-inputs-1"
                type="number"
                name="phoneNumber"
                placeholder="Phone No*"
                value={userState.currentUser.phoneNumber}
                onChange={handleChange_}
                required
              ></input>
              <p className="contact-us-message">Prefer WhatsApp phone no.</p>
              {phoneNumberAlert ? (
                <p style={{ color: "red" }}>Invalid Phone Number</p>
              ) : (
                <div></div>
              )}
            </div>

            <input
              className="contact-us-left-section-form-inputs"
              type="email"
              name="emailId"
              placeholder="Email Id"
              value={userState.currentUser.emailId}
              onChange={handleChange_}
              required
            ></input>
            <input
              className="contact-us-left-section-form-inputs"
              type="text"
              name="city"
              placeholder="City*"
              value={userState.currentUser.city}
              onChange={handleChange_}
              required
            ></input>

            <div className="contact-us-left-section-form-input-container">
              <input
                className="contact-us-left-section-form-inputs-1"
                type="text"
                name="message"
                placeholder="Your Message here"
                value={userState.currentUser.message}
                onChange={handleChange_}
              ></input>
              <p className="contact-us-message">(Max 100 Characters)</p>
            </div>

            {phoneNumberAlert ? (
              <div></div>
            ) : (
              <button type="submit" className="contact-us-submit-btn">
                Submit
              </button>
            )}
          </form>

          <section className="contact-us-right-section">
            <div className="contact-us-right-section-first-row">
              <p className="contact-us-right-section-subheading">Contact</p>

              <div className="contact-us-right-section-row1">
                <i className="fas fa-phone-alt contact-fontawesome-style"></i>

                <p className="contact-us-right-section-para">
                  +912040038718, 9822900054, Whatsapp Contact:8806217212
                </p>
              </div>

              <div className="contact-us-right-section-row1">
                <i className="fas fa-envelope contact-fontawesome-style"></i>
                <p className="contact-us-right-section-para">
                  info@chitralworld.com
                </p>
              </div>
              <div className="contact-us-right-section-row1">
                <i className="fas fa-envelope contact-fontawesome-style"></i>
                <p className="contact-us-right-section-para">
                  chitralpune@gmail.com
                </p>
              </div>
            </div>
            <div className="contact-us-right-section-first-row">
              <p className="contact-us-right-section-subheading">Address</p>

              <div className="contact-us-right-section-row1">
                <i className="fas fa-map-marker-alt contact-fontawesome-style"></i>
                <p className="contact-us-right-section-para">
                  Office No 82, 83,84 ,'A' Wing,6th floor
                  K.K.Market,Dhanakawadi,Pune-Satara Road
                </p>
              </div>
            </div>
            <div className="contact-us-right-section-first-row">
              <p className="contact-us-right-section-subheading">
                Social Links
              </p>

              <div className="contact-us-right-section-media-container">
                <a href="#" className="mr-4">
                  <i className="fab fa-twitter contact-social-media-icon"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCgToYo7yF-jhWPPHir7x3Hw/videos"
                  target="_blank"
                  className="mr-4"
                >
                  <i className="fab fa-youtube contact-social-media-icon"></i>
                </a>
                <a href="#" className="mr-4">
                  <i className="fab fa-instagram contact-social-media-icon"></i>
                </a>
                <a
                  href=" https://www.facebook.com/Chitralworld"
                  target="_blank"
                  className="mr-4"
                >
                  <i className="fab fa-facebook contact-social-media-icon"></i>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
