import React from "react";
import "./AboutUs.css";
import AboutUsLogo from "../../assets/AboutUs/AboutUsLogo.webp";
import AboutUsImage from "../../assets/AboutUs/AboutUsImage.webp";
import OurStoryImg1 from "../../assets/AboutUs/OurStoryImg1.webp";
import OurStoryImg2 from "../../assets/AboutUs/OurStoryImg2.webp";
import OurStoryImg3 from "../../assets/AboutUs/OurStoryImg3.webp";
import OurStoryImg4 from "../../assets/AboutUs/OurStoryImg4.webp";
import OurStoryImg5 from "../../assets/AboutUs/OurStoryImg5.webp";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import MentorsAndTrainers from "../mentorsAndTrainers/MentorsAndTrainers";
import Testimonals from "../testimonials/Testimonials";
// import Contact from "../components/contactForm/Contact";
import ContactForm from "../contactForm/ContactForm";
import Contact from "../contactForm/Contact";

// SEO HELMET
import { Seo } from "../seo/Seo";
import seoData from "../../data/seoData.json";

const AboutUs = () => {
  return (
    <>
      {sessionStorage.getItem("lang") === "Mar" ? (
        <Seo
          title={seoData.about.marathi.title}
          description={seoData.about.marathi.description}
          cardTitle={seoData.about.marathi.cardTitle}
          cardImage={seoData.about.marathi.cardImage}
          cardDescription={seoData.about.marathi.cardDescription}
          cardUrl={seoData.about.marathi.cardUrl}
          cardType={seoData.about.marathi.cardType}
        />
      ) : (
        <Seo
          title={seoData.about.english.title}
          description={seoData.about.english.description}
          keyword={seoData.about.english.keyword}
          cardTitle={seoData.about.english.cardTitle}
          cardImage={seoData.about.english.cardImage}
          cardDescription={seoData.about.english.cardDescription}
          cardUrl={seoData.about.english.cardUrl}
          cardType={seoData.about.english.cardType}
        />
      )}
      <Navbar />
      <div>
        <div className="about-us-heading-container">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <h2 className="about-us-heading">चित्रलबद्दल</h2>
          ) : (
            <h2 className="about-us-heading">About Us</h2>
          )}
          <hr />
        </div>
        <div className="about-us-first-row-container">
          <section className="about-us-first-row-column1">
            {sessionStorage.getItem("lang") === "Mar" ? (
              <p>
                चित्रल वर्ल्ड ही व्यक्तिमत्व विकास आणि प्रेरणादायी
                प्रशिक्षणकक्रम राबविणारी पुणेस्थित संस्था आहे . आतापर्यंत गेल्या
                दोन दशकांत संस्थेच्या विविध प्रशिक्षण कार्यक्रमाच्या माध्यमातून
                हजारो लोकांचे आयुष्य बदलले आहे. समाजातील विविध स्तरातील लोकांना
                चित्रल मुळे त्यांच्यातील क्षमता लक्षात आली आणि श्री हनुमंत
                मेमाणे सर यांच्या मार्गदर्शना मुळे त्यांच्या वैयक्तिक आणि
                व्यावसायिक जीवनास एक नवी दिशा मिळाली. महाराष्ट्र हे नामवंत
                वक्त्यांचे राज्य मानले जाते. येथील वक्तृत्वाचा दबदबा देशभर आहे.
                अशा या महाराष्ट्रात 2002 साली भाषणासारख्या विषयाची पायाभरणी
                चित्रलने केली. आणि पाहता पाहता चित्रल म्हणजे भाषण आणि भाषणासाठी
                फक्त चित्रल असे एका समीकरण तयार झाले. पुढे वक्तृत्वाबरोबरच
                अष्टपैलू व्यक्तिमत्व घडावी या उद्देशाने विविध जीवनउपयोगी
                प्रशिक्षण क्रम चित्रलने सुरु केले. आणि अल्पवधीतच त्याला
                राज्यभरातून कमालीची लोकप्रियता मिळाली. लोकांच्या आयुष्यात अनेक
                सकारात्मक बदल घडून येऊ लागले. आणि यातूनच समाजापुढे आदर्श निर्माण
                करणाऱ्या यशोगाथा उदयास आल्या. आजही वक्तृत्व, नेतृत्व आणि
                कर्तृत्व या यशस्वी जीवनाच्या त्रिसूत्री साठी चित्रलचे नाव
                अग्रगण्य आहे.
              </p>
            ) : (
              <p>
                Chitral World is a Pune based organization that conducts
                personality development and inspirational training courses. So
                far in the last two decades, thousands of lives have been
                changed through the organization's various training programs.
                People from different walks of life realized their potential
                through Chitral and with the guidance of Shri Hanumant Memane
                Sir, their personal and professional life got a new
                direction.Maharashtra is considered to be the state of eminent
                speakers. The rhetoric here is all over the country. In such
                Maharashtra, Chitral laid the foundation of a subject like
                speech in 2002. And looking at it, Chitral is an equation that
                is just Chitral for speech and speech. Chitral started various
                life skills trainings with the aim of developing an all-round
                personality along with oratory.And in a short time, it gained
                tremendous popularity across the state. Many positive changes
                began to take place in people's lives. And out of this came
                success stories that set an example for the society. Even today,
                Chitral's name is at the forefront of the triad of successful
                life of rhetoric, leadership and accomplishment.
              </p>
            )}
          </section>
          <section className="about-us-first-row-column2">
            <img className="about-us-first-row-column2-img" src={AboutUsLogo} />
          </section>
        </div>
        <div className="about-us-second-row-container">
          <section className="about-us-second-row-column1">
            <img
              className="about-us-second-row-column1-img"
              src={AboutUsImage}
            />
            <h3 className="about-us-second-row-column1-name">
              Prof. Hanumant Memane.
            </h3>
            <p className="about-us-second-row-column1-para">
              B.E.Mechanical,N.F.NLP(USA),ANLP(India) Hypno-Therapist,Public
              Speaking, Leadership Developement, Mind & memory Trainer.
            </p>
          </section>
          <section className="about-us-second-row-column2">
            {sessionStorage.getItem("lang") === "Mar" ? (
              <>
                <h2 className="about-us-second-row-column2-heading">
                  प्रशिक्षका बद्दल
                </h2>
                <p className="about-us-second-row-column2-para">
                  प्रा. हनुमंत मेमाणे हे चित्रल वर्ल्ड प्रशिक्षण संस्थेचे मुख्य
                  प्रशिक्षक असून गेली वीस वर्षे ते ज्ञानदानाचे कार्य अविरतपणे
                  करीत आहेत. श्री हनुमंत मेमाणे यांनी पुण्यातील नामांकित
                  शैक्षणिक तसेच व्यवसाय प्रशिक्षण संस्थेत अध्यापन केले आहे. २००२
                  साली त्यांनी चित्रल या प्रशिक्षण संस्थेची स्थापना केली.
                  लोकांच्या आयुष्यात सकारात्मकता पेरणारे आणि त्यांना यशाच्या
                  उंचीवर नेणारे प्रशिक्षण त्यांनी इथे देण्यास सुरुवात केली.
                  सुरुवातीला घरातून प्रशिक्षण देण्यास सुरुवात करण्यापासून ते
                  सध्या स्वतःचे अत्याधुनिक ट्रेनींग सेंटर चालविण्यापर्यंतचा
                  त्यांचा प्रवास थक्क करणारा आहे. ते प्रशिक्षण देत असलेल्या
                  चित्रल प्रशिक्षण संस्थेतून आजपर्यंत ६ लाखाहून अधिक लोकांनी
                  विविध प्रशिक्षण क्रमांचा लाभ घेतलेला आहे. ज्यात
                  राजकीय,सामाजिक,शैक्षणिक, प्रशासकीय आणि इतर सर्वच क्षेत्रातील
                  दिग्गज व्यक्तिमत्वाचा समावेश होतो. आज चित्रल वर्ल्ड ही
                  महाराष्ट्रातील एक अग्रगण्य प्रशिक्षण संस्था असून श्री हनुमंत
                  मेमाणे यांचा नावलौकिकही तितकाच मोठा आहे.
                </p>
              </>
            ) : (
              <>
                <h2 className="about-us-second-row-column2-heading">
                  Chitral Trainer
                </h2>
                <p className="about-us-second-row-column2-para">
                  Prof.Hanumant Memane is the head coach of Chitral World
                  Training Institute and has been imparting knowledge for the
                  last twenty years. Mr. Hanumant Memane has taught at a reputed
                  educational and vocational training institute in Pune.In 2002,
                  he set up a training institute called Chitral. He started
                  imparting training here which would sow positivity in people's
                  lives and take them to the heights of success. Their journey
                  from start-up training at home to running their own
                  state-of-the-art training center is astounding.Till date, more
                  than 6 lakh people have benefited from various training
                  courses from the Chitral Training Institute. Which includes
                  veteran personalities from political, social, educational,
                  administrative and all other fields. Today, Chitral World is
                  one of the leading training institutes in Maharashtra and Mr.
                  Hanumant Memane's reputation is equally great.
                </p>
              </>
            )}
          </section>
        </div>

        <MentorsAndTrainers />

        <div className="about-us-third-row-container">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <>
              <div className="about-us-third-row-boxes">
                <h3 className="about-us-third-row-boxes-heading">दृष्टी</h3>

                <p className="about-us-third-row-boxes-para">
                  लोकांना त्यांच्यात असणाऱ्या अमर्यादित क्षमतांची जाणीव करून
                  देण्यास मदत करणे
                </p>
              </div>
              <div className="about-us-third-row-boxes">
                <h3 className="about-us-third-row-boxes-heading">ध्येय</h3>

                <p className="about-us-third-row-boxes-para">
                  2034 पर्यंत राजकीय व व्यवसाय प्रशिक्षण मध्ये क्र .1 असणे तसेच
                  ९ लाख आणि त्यापेक्षा अधिक कुटुंबे घडविणे
                </p>
              </div>
              <div className="about-us-third-row-boxes">
                <h3 className="about-us-third-row-boxes-heading">मूल्ये</h3>

                <div className="about-us-third-row-boxes-para1">
                  <div className="about-us-card-3sub-container">
                    <i className="fas fa-circle about-us-dot-icon"></i>
                    <p>वास्तवता</p>
                  </div>
                  <div className="about-us-card-3sub-container">
                    <i className="fas fa-circle about-us-dot-icon"></i>
                    <p>जबाबदारीची जाणीव</p>
                  </div>
                  <div className="about-us-card-3sub-container">
                    <i className="fas fa-circle about-us-dot-icon"></i>
                    <p>नाविन्यपूर्ण कल्पना</p>
                  </div>
                  <div className="about-us-card-3sub-container">
                    <i className="fas fa-circle about-us-dot-icon"></i>
                    <p>पैशाचे महत्व</p>
                  </div>
                  <div className="about-us-card-3sub-container">
                    <i className="fas fa-circle about-us-dot-icon"></i>
                    <p>प्रगतीत सातत्य</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="about-us-third-row-boxes">
                <h3 className="about-us-third-row-boxes-heading">VISION</h3>

                <p className="about-us-third-row-boxes-para">
                  To help people by making them realize there full Potential.
                </p>
              </div>
              <div className="about-us-third-row-boxes">
                <h3 className="about-us-third-row-boxes-heading">MISSION</h3>

                <p className="about-us-third-row-boxes-para">
                  To be the No.1 in Political & Business Training & to train &
                  built 9 lacks successful families by 2034..
                </p>
              </div>
              <div className="about-us-third-row-boxes">
                <h3 className="about-us-third-row-boxes-heading">VALUE</h3>

                <div className="about-us-third-row-boxes-para1">
                  <div className="about-us-card-3sub-container">
                    <i className="fas fa-circle about-us-dot-icon"></i>
                    <p>Be Realize</p>
                  </div>
                  <div className="about-us-card-3sub-container">
                    <i className="fas fa-circle about-us-dot-icon"></i>
                    <p>Responsibility</p>
                  </div>
                  <div className="about-us-card-3sub-container">
                    <i className="fas fa-circle about-us-dot-icon"></i>
                    <p>Innovative Ideas</p>
                  </div>
                  <div className="about-us-card-3sub-container">
                    <i className="fas fa-circle about-us-dot-icon"></i>
                    <p>Value for Money</p>
                  </div>
                  <div className="about-us-card-3sub-container">
                    <i className="fas fa-circle about-us-dot-icon"></i>
                    <p>Continuous Improvement</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="our-story-container">
          <div className="our-story-heading-container">
            <h3 className="our-story-heading">Our Story</h3>
            <hr className="our-story-heading-hr" />
          </div>
          <p className="our-story-para">
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            imperdiet morbi pellentesque vel suspendisse sed non nulla. Porta
            parturient et nisl pulvinar donec. Volutpat morbi risus, ipsum
            viverra volutpat. */}
          </p>
        </div>
        <section className="our-story-container">
          <div className="our-story-section-left">
            <div className="our-story-section-left-img">
              <img className="story-section-left-img" src={OurStoryImg1} />
            </div>

            <div className="our-story-section-circle">
              <div className="our-story-section-inner-circle"></div>
            </div>

            <div className="our-story-section-para-left-wrapper">
              <p className="our-story-section-para">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                imperdiet morbi pellentesque vel suspendisse sed non nulla.
                Porta parturient et nisl pulvinar donec. Volutpat morbi risus,
                ipsum viverra volutpat. */}
              </p>
            </div>
          </div>

          <div className="about-us-dashed-border"></div>

          <div className="our-story-section-right">
            <div className="our-story-section-para-right-wrapper">
              <p className="our-story-section-para">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                imperdiet morbi pellentesque vel suspendisse sed non nulla.
                Porta parturient et nisl pulvinar donec. Volutpat morbi risus,
                ipsum viverra volutpat. */}
              </p>
            </div>

            <div className="our-story-section-circle">
              <div className="our-story-section-inner-circle"></div>
            </div>

            <div className="our-story-section-right-img">
              <img className="story-section-right-img" src={OurStoryImg2} />
            </div>
          </div>

          <div className="about-us-dashed-border"></div>

          <div className="our-story-section-left">
            <div className="our-story-section-left-img">
              <img className="story-section-left-img" src={OurStoryImg3} />
            </div>

            <div className="our-story-section-circle">
              <div className="our-story-section-inner-circle"></div>
            </div>

            <div className="our-story-section-para-left-wrapper">
              <p className="our-story-section-para">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                imperdiet morbi pellentesque vel suspendisse sed non nulla.
                Porta parturient et nisl pulvinar donec. Volutpat morbi risus,
                ipsum viverra volutpat. */}
              </p>
            </div>
          </div>

          <div className="about-us-dashed-border"></div>

          <div className="our-story-section-right">
            <div className="our-story-section-para-right-wrapper">
              <p className="our-story-section-para">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                imperdiet morbi pellentesque vel suspendisse sed non nulla.
                Porta parturient et nisl pulvinar donec. Volutpat morbi risus,
                ipsum viverra volutpat. */}
              </p>
            </div>

            <div className="our-story-section-circle">
              <div className="our-story-section-inner-circle"></div>
            </div>
            <div className="our-story-section-right-img">
              <img className="story-section-right-img" src={OurStoryImg4} />
            </div>
          </div>

          <div className="about-us-dashed-border"></div>

          <div className="our-story-section-left">
            <div className="our-story-section-left-img">
              <img className="story-section-left-img" src={OurStoryImg5} />
            </div>

            <div className="our-story-section-circle">
              <div className="our-story-section-inner-circle"></div>
            </div>

            <div className="our-story-section-para-left-wrapper">
              <p className="our-story-section-para">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                imperdiet morbi pellentesque vel suspendisse sed non nulla.
                Porta parturient et nisl pulvinar donec. Volutpat morbi risus,
                ipsum viverra volutpat.Lorem */}
              </p>
            </div>
          </div>
        </section>
      </div>
      <Testimonals />
      <ContactForm />
      <Footer />
    </>
  );
};

export default AboutUs;
