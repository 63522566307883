import React, { useState } from "react";
import firebase from "../../firebase/Firebase";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import "./Footer.css";

const today = new Date();
const Footer = () => {
  const db = firebase.firestore();
  const [phoneNumberAlert, setPhoneNumberAlert] = useState(false);

  // const [name, setName] = useState("");
  // const [number, setNumber] = useState("");
  // const [feedback, setFeedback] = useState("");
  const [userState, setUserState] = useState({
    currentUser: {
      name: "",
      number: "",
      feedback: "",
    },
  });

  const submitFeedback = (e) => {
    e.preventDefault();

    db.collection("feedbackForm")
      // .add({
      //   name: name,
      //   feedback: feedback,
      //   number: number,
      //   date: firebase.firestore.Timestamp.fromDate(new Date()),
      // })
      .add({
        name: userState.currentUser.name,
        feedback: userState.currentUser.feedback,
        number: userState.currentUser.number,
        date: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then(() => {
        swal({
          icon: "success",
          title: "Thank you for your feedback",
        });
        setUserState({
          currentUser: {
            name: "",
            number: "",
            feedback: "",
          },
        });
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleChange_ = (e) => {
    console.log("presseed", e.target);
    if (e.target.name === "name") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name === "number") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
      if (userState.currentUser.number.length === 9) {
        setPhoneNumberAlert(false);
      } else {
        setPhoneNumberAlert(true);
      }
    } else if (e.target.name === "feedback") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  return (
    <>
      <section className="Footer-wrapper">
        <footer className="Footer-innner-wrapper">
          <section>
            <div className="Footer-container">
              <div className="Footer-columns">
                <ul className="Footer-column">
                  <li className="Footer-column-items">
                    <h2 className="Footer-column-title">Quick Links</h2>
                  </li>
                  <li className="Footer-column-items">
                    <Link to="/courses-List"> Courses</Link>
                  </li>
                  <li className="Footer-column-items">
                    <Link to="/event-Page">Events</Link>
                  </li>
                  <li className="Footer-column-items">
                    <Link to="/video-gallery">Video Gallery</Link>
                  </li>
                  <li className="Footer-column-items">
                    <Link to="/image-gallery">Image Gallery</Link>
                  </li>
                </ul>
              </div>

              <div className="Footer-columns">
                <ul className="Footer-column">
                  <li className="Footer-column-items">
                    <h2 className="Footer-column-title">Information</h2>
                  </li>
                  <li className="Footer-column-items">
                    <a href="#">FAQ</a>
                  </li>
                  <li className="Footer-column-items">
                    <Link to="/blogs">Blog</Link>
                  </li>
                </ul>
              </div>
              <div className="Footer-columns">
                <ul className="Footer-column">
                  <li className="Footer-column-items">
                    <h2 className="Footer-column-title">Company</h2>
                  </li>
                  <li className="Footer-column-items">
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li className="Footer-column-items">
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li className="Footer-column-items">
                    <a href="#">Careers</a>
                  </li>
                </ul>
              </div>
            </div>
            <hr className="Footer-horizontal-rule" />

            <div className="footer-bottom-container">
              <div className="Footer-bottom">
                <span className="Footer-bottom-text">
                  <p className="copyright-link">
                    &copy; {today.getFullYear()}
                    <a
                      href="https://chitralworld.com/"
                      className="copyright-link"
                      target="_abc"
                    >
                      &nbsp;Chitral World &nbsp;|&nbsp;
                    </a>
                    All Rights Reserved &nbsp;|&nbsp;
                  </p>
                </span>
                <span className="Footer-bottom-text">Terms |&nbsp;</span>
                <span className="Footer-bottom-text">Privacy |&nbsp;</span>
                <span className="Footer-bottom-text">Cookies</span>
              </div>
              <div className="Footer-fontawesome">
                <i className="fab fa-instagram fontawesome-style"></i>

                <a href="https://www.facebook.com/Chitralworld" target="_blank">
                  <i className="fab fa-facebook fontawesome-style"></i>
                </a>

                <a
                  href="https://www.youtube.com/channel/UCgToYo7yF-jhWPPHir7x3Hw/videos"
                  target="_blank"
                >
                  <i className="fab fa-youtube fontawesome-style"></i>
                </a>

                <i className="fab fa-twitter fontawesome-style"></i>
              </div>
            </div>
            <div className="flex-bottom-container Footer-bottom-text">
              Designed and Developed By &nbsp;
              <a
                href="https://www.scrobits.com/"
                className="scrobits-link"
                target="_abc"
              >
                Scrobits Technologies
              </a>
            </div>
          </section>
          <section className="footer-section-2">
            {sessionStorage.getItem("lang") === "Mar" ? (
              <p className="footer-section-2-heading">
                चित्रलबद्दलचा तुमचा अनुभव आम्हाला सांगा
              </p>
            ) : (
              <p className="footer-section-2-heading">
                Tell us about your experience with Chitral
              </p>
            )}
            <form
              className="footer-section-2-form"
              onSubmit={(e) => {
                submitFeedback(e);
              }}
            >
              <input
                className="footer-section-2-input"
                placeholder="Full Name*"
                name="name"
                value={userState.currentUser.name}
                // onChange={(e) => {
                //   setName(e.target.value);
                // }}
                onChange={handleChange_}
                required
              />
              <input
                className="footer-section-2-input"
                placeholder="Phone No*"
                // value={number}
                type="number"
                name="number"
                value={userState.currentUser.number}
                // onChange={(e) => {
                //   setNumber(e.target.value);
                // }}
                onChange={handleChange_}
                required
              />

              {phoneNumberAlert ? (
                <p style={{ color: "red" }}>Invalid Phone Number</p>
              ) : (
                <div></div>
              )}
              <input
                className="footer-section-2-input"
                placeholder="Your Feedback"
                name="feedback"
                // value={feedback}
                value={userState.currentUser.feedback}
                // onChange={(e) => {
                //   setFeedback(e.target.value);
                // }}
                onChange={handleChange_}
                required
              />
              {/* <button type="submit" className="footer-section-2-btn">
                Submit Feedback
              </button> */}
              {phoneNumberAlert ? (
                <div></div>
              ) : (
                <button type="submit" className="footer-section-2-btn">
                  Submit Feedback
                </button>
              )}
            </form>
          </section>
        </footer>
      </section>
    </>
  );
};

export default Footer;
