import React, { useState, useEffect } from "react";
import Navbar from "../navbar2/Navbar2";
import firebase from "../../firebase/Firebase";
import "./HomePage.css";
import Marquee from "react-fast-marquee";
import Testimonials from "../testimonials/Testimonials";
import SkillsComponent from "../skillsComponent/SkillsComponent";
import Footer from "../footer/Footer";
import seoData from "../../data/seoData.json";
import TwentyYears from "../../assets/HeroSection/TwentyYears.webp";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import UpcomingAttractions from "../upcomingAttractions/UpcomingAttractions";

// SEO HELMET
import { Seo } from "../seo/Seo";
import VideoSection from "./videosection/VideoSection";
import GallerSection from "./gallerysection/GallerSection";
import TrainingProgram from "./trainingprogram/TrainingProgram";
import HeroSection from "./hero/HeroSection";
import ContactForm from "../contactForm/ContactForm";

const HomePage = () => {
  const [rollingText, setRollingText] = useState([]);
  const db = firebase.firestore();
  useEffect(() => {
    getRollingText();
  }, []);

  const getRollingText = () => {
    db.collection("rollingEvent").onSnapshot((snapshot) => {
      setRollingText(snapshot.docs.map((doc) => doc.data()));
    });
  };

  return (
    <>
      {sessionStorage.getItem("lang") === "Mar" ? (
        <Seo
          title={seoData.home.marathi.title}
          description={seoData.home.marathi.description}
          keyword={seoData.home.marathi.keyword}
          cardTitle={seoData.home.marathi.cardTitle}
          cardImage={seoData.home.marathi.cardImage}
          cardDescription={seoData.home.marathi.cardDescription}
          cardUrl={seoData.home.marathi.cardUrl}
          cardType={seoData.home.marathi.cardType}
        />
      ) : (
        <Seo
          title={seoData.home.english.title}
          description={seoData.home.english.description}
          keyword={seoData.home.english.keyword}
          cardTitle={seoData.home.english.cardTitle}
          cardImage={seoData.home.english.cardImage}
          cardDescription={seoData.home.english.cardDescription}
          cardUrl={seoData.home.english.cardUrl}
          cardType={seoData.home.english.cardType}
        />
      )}

      <div>
        <Navbar />
        <HeroSection />
      </div>

      <div>
        <Marquee
          gradient={false}
          gradientWidth="400"
          className="homepage-marquee"
          speed={60}
        >
          {rollingText && rollingText.length > 0 ? (
            rollingText.map((data) => {
              return (
                <>
                  {sessionStorage.getItem("lang") === "Mar" ? (
                    <p className="homepage-marquee-items">{data.mrEvent}</p>
                  ) : (
                    <p className="homepage-marquee-items">{data.enEvent}</p>
                  )}
                </>
              );
            })
          ) : (
            <></>
          )}
        </Marquee>
        <UpcomingAttractions />
        <Testimonials />
        <div>
          <img className="home-page-story-img" src={TwentyYears} />
        </div>

        <SkillsComponent />
      </div>

      <TrainingProgram />

      <GallerSection />

      <VideoSection />

      <div id="contactform">
        <ContactForm />
      </div>

      <Footer />
    </>
  );
};

export default HomePage;
