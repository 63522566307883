import React, { useEffect, useState } from "react";
import "../HomePage.css";
import firebase from "../../../firebase/Firebase";
import { Link } from "react-router-dom";
function VideoSection() {
  const [videos, setVideos] = useState("");
  const db = firebase.firestore();
  useEffect(() => {
    getvideos();
  }, []);
  const getvideos = () => {
    db.collection("videoGallery").onSnapshot((snapshot) => {
      setVideos(snapshot.docs.map((doc) => doc.data()));
    });
  };
  return (
    <div>
      <div className="homepage-video-outer-container">
        <div className="homepage-video-heading-cont">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <h3 className="homepage-video-heading">व्हिडिओ गॅलरी</h3>
          ) : (
            <h3 className="homepage-video-heading">Video gallery</h3>
          )}
          <hr className="homepage-video-heading-line" />
        </div>

        <div className="homepage-video-container">
          {videos && videos.length > 0 ? (
            videos.slice(0, 4).map((curr) => {
              return (
                <div>
                  <iframe
                    className="homepage-video"
                    src={curr.link}
                    frameBorder="0"
                    allow="autoplay;encrypted-media"
                    allowFullScreen
                  ></iframe>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
        <Link to={`/video-gallery`} className="video-show-more">
          See More<i class="fas fa-arrow-right arrow-right-icon"></i>
        </Link>
      </div>
    </div>
  );
}

export default VideoSection;
