import React, { useState } from "react";
import firebase from "../../firebase/Firebase";
import swal from "sweetalert";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import "./RegisterForm.css";
import { useLocation } from "react-router-dom";

const RegisterForm = () => {
  var db = firebase.firestore();
  const [phoneNumberAlert, setPhoneNumberAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const [userState, setUserState] = useState({
    currentUser: {
      fullName: "",
      phoneNumber: "",
      emailId: "",
      city: "",
      message: "",
    },
  });

  const location = useLocation();
  const { title } = location.state;

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection("courseFrm")

      .add({
        courseName: title,
        date: firebase.firestore.Timestamp.fromDate(new Date()),
        fullName: userState.currentUser.fullName,
        emailId: userState.currentUser.emailId,
        city: userState.currentUser.city,
        phoneNumber: userState.currentUser.phoneNumber,
        message: userState.currentUser.message,
      })

      .then(() => {
        swal({
          icon: "success",
          title: "Our team will contact you",
        });
        setUserState({
          currentUser: {
            fullName: "",
            phoneNumber: "",
            emailId: "",
            city: "",
            message: "",
          },
        });
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  // const handleChange_ = (e) => {
  //   console.log("presseed", e.target);
  //   setUserState({
  //     currentUser: {
  //       ...userState.currentUser,
  //       [e.target.name]: e.target.value,
  //     },
  //   });
  // };

  const handleChange_ = (e) => {
    if (e.target.name === "fullName") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name === "emailId") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name === "phoneNumber") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
      if (userState.currentUser.phoneNumber.length === 9) {
        setPhoneNumberAlert(false);
      } else {
        setPhoneNumberAlert(true);
      }
    } else if (e.target.name === "city") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name === "message") {
      setUserState({
        currentUser: {
          ...userState.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  return (
    <>
      <Navbar />
      <div className="register-form-page-container">
        <div className="contact-form-heading-container">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <h2 className="register-form-heading">रजिस्टर करा</h2>
          ) : (
            <h2 className="register-form-heading1">Register Now</h2>
          )}
          <hr className="contact-form-heading-hr-tag" />
        </div>

        <div className="contact-form-wrapper">
          <form
            className="contact-us-left-section-form"
            onSubmit={handleSubmit}
          >
            <p className="registerForm-course-title">{title}</p>
            <input
              required
              className="contact-us-left-section-form-inputs"
              type="text"
              name="fullName"
              placeholder="Full Name*"
              value={userState.currentUser.fullName}
              onChange={handleChange_}
            ></input>

            <div className="contact-us-left-section-form-input-container">
              <input
                className="contact-us-left-section-form-inputs-1"
                type="number"
                name="phoneNumber"
                placeholder="Phone No*"
                value={userState.currentUser.phoneNumber}
                onChange={handleChange_}
                required
              ></input>
              <p className="contact-us-message">Prefer WhatsApp phone no.</p>
              {phoneNumberAlert ? (
                <p style={{ color: "red" }}>Invalid Phone Number</p>
              ) : (
                <div></div>
              )}
            </div>

            <input
              className="contact-us-left-section-form-inputs"
              type="email"
              placeholder="Email Id"
              required
              name="emailId"
              value={userState.currentUser.emailId}
              onChange={handleChange_}
            ></input>
            <input
              className="contact-us-left-section-form-inputs"
              type="text"
              required
              placeholder="City*"
              name="city"
              value={userState.currentUser.city}
              onChange={handleChange_}
            ></input>

            <div className="contact-us-left-section-form-input-container">
              <input
                className="contact-us-left-section-form-inputs-1"
                type="text"
                required
                name="message"
                placeholder="Your Message here"
                value={userState.currentUser.message}
                onChange={handleChange_}
              ></input>
              <p className="contact-us-message">(Max 100 Characters)</p>
            </div>

            {phoneNumberAlert ? (
              <div></div>
            ) : (
              <button type="submit" className="contact-us-submit-btn">
                Submit
              </button>
            )}
          </form>

          <section className="contact-us-right-section">
            <div className="contact-us-right-section-first-row">
              <p className="contact-us-right-section-subheading">Contact</p>

              <div className="contact-us-right-section-row1">
                <i className="fas fa-phone-alt contact-fontawesome-style"></i>

                <p className="contact-us-right-section-para">
                  +020 123 456 789
                </p>
              </div>

              <div className="contact-us-right-section-row1">
                <i className="fas fa-envelope contact-fontawesome-style"></i>
                <p className="contact-us-right-section-para">
                  info@chitralworld.com
                </p>
              </div>
            </div>
            <div className="contact-us-right-section-first-row">
              <p className="contact-us-right-section-subheading">Address</p>

              <div className="contact-us-right-section-row1">
                <i className="fas fa-map-marker-alt contact-fontawesome-style"></i>
                <p className="contact-us-right-section-para">
                  Office No 82, 83,84 ,'A' Wing,6th floor
                  K.K.Market,Dhanakawadi,Pune-Satara Road
                </p>
              </div>
            </div>
            <div className="contact-us-right-section-first-row">
              <p className="contact-us-right-section-subheading">
                Social Links
              </p>

              <div className="contact-us-right-section-media-container">
                <a href="#" className="mr-4">
                  <i className="fab fa-twitter contact-social-media-icon"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCgToYo7yF-jhWPPHir7x3Hw/videos"
                  target="_blank"
                  className="mr-4"
                >
                  <i className="fab fa-facebook contact-social-media-icon "></i>
                </a>
                <a href="#" className="mr-4">
                  <i className="fab fa-instagram contact-social-media-icon"></i>
                </a>

                <a
                  href="https://www.facebook.com/Chitralworld"
                  target="_blank"
                  className="mr-4"
                >
                  <i className="fab fa-youtube contact-social-media-icon"></i>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RegisterForm;
