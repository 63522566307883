import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import "./Error404.css";

const Error404 = () => {
  return (
    <>
      <Navbar />
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>WE ARE SORRY, PAGE NOT FOUND</h2>
          <p>
            THE PAGE YOU ARE LOOKING FOR MIGHT HAVE BEEN REMOVED,
            <br /> HAD ITS NAME CHANGED OR IS TEMPORARILY UNAVAILABLE.
          </p>
          <NavLink className="notfound-btn" to="/">Back to Home Page</NavLink>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Error404;
