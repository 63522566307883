import React, { useState, useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import firebase from "../../firebase/Firebase";
import Pagination from "./Pagination";
import { Link, useRouteMatch } from "react-router-dom";
import "./Blogs.css";

import { Seo } from "../seo/Seo";
import seoData from '../../data/seoData.json'

const Blogs = () => {
  const [blogList, setBlogList] = useState([]);
  const [showPerPage, setShowPerPage] = useState(4);
  const db = firebase.firestore();
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  useEffect(() => {
    db.collection("blogs")
      .orderBy("date", "desc")
      .get()
      .then((docs) => {
        if (!docs.empty) {
          let allArticles = [];
          docs.forEach(function(doc) {
            const article = {
              id: doc.id,
              ...doc.data(),
            };
            allArticles.push(article);
          });

          setBlogList(allArticles);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  return (
    <>
      {sessionStorage.getItem("lang") === "Mar" ? (
        <Seo
          title={seoData.blogs.marathi.title}
          description={seoData.blogs.marathi.description}
          keyword={seoData.blogs.marathi.keyword}
          cardTitle={seoData.blogs.marathi.cardTitle}
          cardImage={seoData.blogs.marathi.cardImage}
          cardDescription={seoData.blogs.marathi.cardDescription}
          cardUrl={seoData.blogs.marathi.cardUrl}
          cardType={seoData.blogs.marathi.cardType}
        />
      ) : (
        <Seo
          title={seoData.blogs.english.title}
          description={seoData.blogs.english.description}
          keyword={seoData.blogs.english.keyword}
          cardTitle={seoData.blogs.english.cardTitle}
          cardImage={seoData.blogs.english.cardImage}
          cardDescription={seoData.blogs.english.cardDescription}
          cardUrl={seoData.blogs.english.cardUrl}
          cardType={seoData.blogs.english.cardType}
        />
      )}
      <Navbar />
      <div>
        <div className="blog-page-img-container">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <p className="blog-page-img-container-text">ब्लॉग्ज</p>
          ) : (
            <p className="blog-page-img-container-text">Blogs</p>
          )}
        </div>
        {blogList && blogList.length > 0 ? (
          <div>
            <div>
              {blogList.slice(0, 1).map((data, idx) => {
                let Date = data.date
                  .toDate()
                  .toDateString()
                  .split(" ");
                let blogDate = Date[2] + " " + Date[1] + " " + Date[3];

                return (
                  <>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/blog-View/${data.id}`}
                    >
                     
                      <div className="bloglist-latest-blog-container">
                        <section className="bloglist-latest-blog-img-wrapper">
                          <img
                            className="bloglist-latest-blog-img"
                            src={data.thumbNail}
                          />
                        </section>
                        <section className="bloglist-latest-blog-content-wrapper">
                          <p className="bloglist-latest-blog-content-heading">
                            {data.title}
                          </p>
                          <p
                            className="bloglist-latest-blog-summary"
                            dangerouslySetInnerHTML={{ __html: data.summary }}
                          />
                          <p className="bloglist-latest-blog-date">
                            {blogDate}
                          </p>
                          <p className="bloglist-latest-blog-authorName">
                            {data.authorName}
                          </p>
                          
                          <a href={"/blog-View/" + data.id + data.title}>
                            Read More
                          </a>
                          <useRouteMatch />
                        </section>
                      </div>
                    </Link>
                  </>
                );
              })}
            </div>
            <div>
              <>
                <div className="all-blog-list-container">
                  <div className="all-blog-list-inner-container">
                    {blogList
                      .slice(pagination.start, pagination.end)
                      .map((blog, id) => {
                        let date = blog.date
                          .toDate()
                          .toDateString()
                          .split(" ");
                        let blogDate = date[2] + " " + date[1] + " " + date[3];

                        return (
                          <div className="all-blog-list-card" key={blog.id}>
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/blog-View/${blog.id}`}
                            >
                              <div className="all-blog-list-card-img-wrapper">
                                <img
                                  className="all-blog-list-card-img"
                                  src={blog.thumbNail}
                                />
                              </div>
                              <p className="all-blog-list-card-title">
                                {blog.title}
                              </p>
                              <p className="all-blog-list-card-summary">
                                {blog.summary}....
                              </p>
                              <div className="all-blog-list-card-date-author-container">
                                <p>{blogDate}</p>
                                <p>{blog.authorName}</p>
                              </div>

                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/blog-View/${blog.id}`}
                              >
                                <p className="blog-card-read-more-btn">
                                  Read more...
                                </p>
                              </Link>
                            </Link>
                          </div>
                        );
                      })}
                  </div>

                  <Pagination
                    showPerPage={showPerPage}
                    onPaginationChange={onPaginationChange}
                    total={blogList.length}
                  />
                </div>
              </>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
