import React, { useState, useEffect } from "react";
import "./ImageGallery.css";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import firebase from "../../firebase/Firebase";
import Pagination from "./Pagination";
import Loader from "../loader/Loader";

const ImageGallery = () => {
  const db = firebase.firestore();

  const [images, setImages] = useState("");
  const [showPerPage, setShowPerPage] = useState(12);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const [model, setModel] = useState(false);

  const [tempimage, setTempimage] = useState("");
  const getImg = (image) => {
    setTempimage(image);
    setModel(true);
  };

  useEffect(() => {
    getImages();
  }, []);

  const getImages = () => {
    db.collection("imageGallery").onSnapshot((snapshot) => {
      setImages(snapshot.docs.map((doc) => doc.data()));
    });
  };

  console.log(tempimage);

  return (
    <>
      <Navbar />
      <div className="image-gallery-container">
        {images && images.length ? (
          <>
            <div className="image-gallery-heading-container">
              <h3 className="image-gallery-heading">Image Gallery</h3>
              <hr className="image-gallery-heading-hr" />
            </div>
            <div>
              <div className={model ? "model open" : "model"}>
                <img src={tempimage} />
                <button
                  className="image-gallery-button"
                  onClick={() => setModel(false)}
                >
                  <i class="fas fa-times cross-icon"></i>
                </button>
              </div>
              <div className="image-gallery-wrapper">
                {images && images.length > 0 ? (
                  images
                    .slice(pagination.start, pagination.end)

                    .map((curr, index) => {
                      return (
                        <div className="image-gallery-inner-wrapper">
                          <img
                            className="image-gallery-images"
                            src={curr.imgLink}
                            key={index}
                            onClick={() => getImg(curr.imgLink)}
                          />
                        </div>
                      );
                    })
                ) : (
                  <></>
                )}
              </div>
              <Pagination
                showPerPage={showPerPage}
                onPaginationChange={onPaginationChange}
                total={images.length}
              />
            </div>
          </>
        ) : (
          <h5><Loader/></h5>
        )}
      </div>
      <div></div>
      <Footer />
    </>
  );
};

export default ImageGallery;
