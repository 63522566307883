import React from "react";
import { Route, Switch } from "react-router-dom";
import AboutUs from "../components/about-us/AboutUs";
import Blogs from "../components/blogs/Blogs";
import BlogView from "../components/blogView/BlogView";
import Chatbot from "../components/chatbot/Chatbot";
import Comment from "../components/comment/Comment";
import Contact from "../components/contactForm/Contact";
import CoursesList from "../components/courses/coursesList/CoursesList";
import CourseView from "../components/courseView/CourseView";
import Error404 from "../components/errorPage/Error404";
import EventView from "../components/EventView/EventView";
import EventPage from "../components/upcomingAttractionsFullView/UpcomingAttractionsFullView";
import HomePage from "../components/Homepage";
import ImageGallery from "../components/imageGallery/ImageGallery";
import RegisterForm from "../components/registerForm/RegisterForm";
import UpcomingAttractions from "../components/upcomingAttractions/UpcomingAttractions";
import UpcomingEvents from "../components/upcomingEvents/UpcomingEvents";
import VideoGallery from "../components/videoGallery/VideoGallery";
import ViewSkill from "../components/viewSkill/ViewSkill";

function RouteConfig() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/courses-List" component={CoursesList} />
        <Route path="/blogs" component={Blogs} />
        <Route path="/blog-View/:id" component={BlogView} />
        <Route path="/upcoming-Events" component={UpcomingEvents} />
        <Route path="/image-gallery" component={ImageGallery} />
        <Route path="/video-gallery" component={VideoGallery} />
        <Route path="/comments" component={Comment} />
        <Route path="/chatbot" component={Chatbot} />
        <Route path="/contact" component={Contact} />
        <Route path="/registerform" component={RegisterForm} />
        <Route path="/upcoming-Attractions" component={UpcomingAttractions} />
        <Route path="/event-View/:id" component={EventView} />
        <Route path="/course-View/:id" component={CourseView} />
        <Route path="/event-Page" component={EventPage} />
        {/* <Route path="/Contact" component={Contact} /> */}
        <Route path="/view-Skill" component={ViewSkill} />
        <Route path="*" component={Error404} />
      </Switch>
    </div>
  );
}

export default RouteConfig;
