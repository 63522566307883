import React, { useState, useEffect } from "react";
import { Carousel } from "3d-react-carousal";
import firebase from "../../firebase/Firebase";
import "./Testimonials.css";
import * as ReactDOMServer from "react-dom/server";

const Testimonials = () => {
  const db = firebase.firestore();

  const [testimonial, setTestimonial] = useState([]);
  let slides;
  useEffect(() => {
    getTestimonials();
  }, []);

  const getTestimonials = () => {
    // return new
    db.collection("testimonials").onSnapshot((snapshot) => {
      setTestimonial(snapshot.docs.map((doc) => doc.data()));
    });
  };

  console.log("testimonials", testimonial);

  testimonial && testimonial.length > 0
    ? (slides = testimonial.map((data, index) => {
        console.log("TestImage", data.image);
        return (
          <div className="slider-card">
            <div className="slider-img-container">
              
              <img className="slider-image" src={data.image} alt="testImg" />
            </div>
            {sessionStorage.getItem("lang") === "Mar" ? (
              <p className="slider-heading">{data.testimonialsTitleMr}</p>
            ) : (
              <p className="slider-heading">{data.testimonialsTitle}</p>
            )}
            {sessionStorage.getItem("lang") === "Mar" ? (
              <p className="slider-para">{data.testimonialsDespMr}</p>
            ) : (
              <p className="slider-para">{data.testimonialsDesp}</p>
            )}
          </div>
        );
      }))
    : (slides = []);
  const callback = function(index) {
  };

  return (
    <>
     
      <div className="testimonials-container">
        <div className="testimonials-heading-container">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <h2 className="testimonials-heading">अनुभव आणि प्रतिक्रिया</h2>
          ) : (
            <h2 className="testimonials-heading">Testimonials</h2>
          )}
          <hr className="testimonials-heading-hr" />
        </div>
        <Carousel
          slides={slides}
          autoplay={true}
          interval={4000}
          onSlideChange={callback}
        />
      </div>
    </>
  );
};

export default Testimonials;
