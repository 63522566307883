import React from "react";
import "../HomePage.css";
import trainingIllu from "../../../assets/HeroSection/trainingIllu.webp";

function TrainingProgram() {
  return (
    <div>
      <div className="home-page-training-programs-container">
        <section>
          <div className="training-programs-heading-container">
            {sessionStorage.getItem("lang") === "Mar" ? (
              <h3 className="training-programs-heading">
                चित्रलच्या विविध प्रशिक्षण
              </h3>
            ) : (
              <h3 className="training-programs-heading">Training Programs</h3>
            )}
            <hr className="training-programs-heading-line" />
          </div>
          {sessionStorage.getItem("lang") === "Mar" ? (
            <>
              <p className="training-programs-para1">
                प्रत्येक क्षेत्रात दिवसेंदिवस स्पर्धा वाढत आहे आणि या
                स्पर्धेबरोबर वेगाने धावण्यासाठी आवश्यक आहे आत्मविश्वास आणि
                स्मार्टनेस. आणि याच गोष्टींना प्राधान्य देऊन या बदलत्या युगात
                चित्रल वर्ल्ड आपल्या विविध प्रशिक्षणाच्या माध्यमातून प्रत्येक
                विद्यार्थ्याला यशस्वी आणि अष्टपैलू व्यक्तिमत्व म्हणून उदयास
                येण्यासाठी मदत करते.
              </p>
              <p className="training-programs-para2">
                आपल्या जीवनाला दिशा देणाऱ्या चित्रलच्या विविध प्रशिक्षणांबाबत
                थोडेसे जाणून घेऊया .....
              </p>
              <a href="#contactform">
                <button className="training-programs-btn">
                  आमच्यापर्यंत पोहोचा
                </button>
              </a>
            </>
          ) : (
            <>
              <p className="training-programs-para1">
                In today's world to sustain in the growing competition one needs
                Confidence & smartness and to stay upgraded always chitral world
                through its various dynamic programme's is creating succesful
                and allrounder perssonalities.
              </p>
              <p className="training-programs-para2">
                Understand about these programmes ...
              </p>
              <a href="#contactform">
                <button className="training-programs-btn">Reach us</button>
              </a>
            </>
          )}
        </section>
        <section className="training-programs-illustration-container">
          <img
            className="training-programs-illustration"
            src={trainingIllu}
            alt="illustation"
          />
        </section>
      </div>
    </div>
  );
}

export default TrainingProgram;
