import React from "react";
import firebase from "firebase";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import "./CourseView.css";

// SEO HELMET
import { Seo } from "../seo/Seo";
import Loader from "../loader/Loader";

const CourseView = () => {
  const db = firebase.firestore();
  const [course, setCourse] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    db.collection("courses")
      .doc(id)
      .get()
      .then((snapshot) => {
        setCourse(snapshot.data());
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);
  return (
    <>
      {sessionStorage.getItem("lang") === "Mar" ? (
        <Seo
          // title={course.titleMr}
          // description={course.learnMr}
          // keyword={course.seoKeyword}
          // cardTitle={course.titleMr}
          title={course.seoTitleMr}
          description={course.seoDescMr}
          keyword={course.seoKeywordMr}
          cardTitle={course.seoTitleMr}
          cardImage="/static/media/ChitralM.47070a5981c86494ad63.webp"
          cardDescription={course.seoDescMr}
          cardUrl="/course-View/:id"
          cardType="website"
        />
      ) : (
        <Seo
          title={course.seoTitle}
          description={course.seoDesc}
          keyword={course.seoKeyword}
          cardTitle={course.seoTitle}
          cardImage="/static/media/ChitralM.47070a5981c86494ad63.webp"
          cardDescription={course.seoDesc}
          cardUrl="/course-View/:id"
          cardType="website"
        />
      )}
      <Navbar />
      <div className="course-view-wrapper">
        {course ? (
          <div className="course-view-container">
            <div className="course-view-heading-container">
              <div>
                <img
                  src={course.image}
                  alt="courseImg"
                  className="course-view-bgImg"
                ></img>
              </div>
              <div>
                {sessionStorage.getItem("lang") === "Mar" ? (
                  <div className="course-view-heading">
                    <h3>{course.titleMr}</h3>
                  </div>
                ) : (
                  <div className="course-view-heading">
                    <h3>{course.title}</h3>
                  </div>
                )}
              </div>
            </div>
            <div className="course-view-learn-container">
              {sessionStorage.getItem("lang") === "Mar" ? (
                <div className="course-view-learn-heading-container">
                  <h4 className="course-view-learn-heading">
                    तुम्ही काय शिकाल
                  </h4>
                  <hr className="course-view-heading-hr" />
                </div>
              ) : (
                <div className="course-view-learn-heading-container">
                  <h4 className="course-view-learn-heading">
                    What you’ll learn
                  </h4>
                  <hr className="course-view-heading-hr" />
                </div>
              )}
              {sessionStorage.getItem("lang") === "Mar" ? (
                <div className="course-view-learn-list">
                  {course.learnMr && course.learnMr.length > 0 ? (
                    course.learnMr.map((data) => {
                      return (
                        <>
                          <ul className="course-view-learn-items">
                            <li>
                              <i className="fas fa-check course-view-learn-check "></i>
                              {data}
                            </li>
                          </ul>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="course-view-learn-list">
                  {course.learn && course.learn.length > 0 ? (
                    course.learn.map((data) => {
                      return (
                        <>
                          <ul className="course-view-learn-items">
                            <li>
                              <i className="fas fa-check course-view-learn-check "></i>
                              {data}
                            </li>
                          </ul>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>

            <div className="content-view-container">
              {sessionStorage.getItem("lang") === "Mar" ? (
                <div className="course-view-content-heading-container">
                  <h4 className="course-view-content-heading">अभ्यासक्रम</h4>
                  <hr className="course-view-content-heading-hr" />
                </div>
              ) : (
                <div className="course-view-content-heading-container">
                  <h4 className="course-view-content-heading">
                    Course Content
                  </h4>
                  <hr className="course-view-content-heading-hr" />
                </div>
              )}
              {sessionStorage.getItem("lang") === "Mar" ? (
                <div className="content-view-list">
                  {course.contentMr && course.contentMr.length > 0 ? (
                    course.contentMr.map((data) => {
                      return (
                        <ul>
                          <li className="content-view-items">{data}</li>
                        </ul>
                      );
                    })
                  ) : (
                    <>
                      <Loader />
                    </>
                  )}
                </div>
              ) : (
                <div className="content-view-list">
                  {course.content && course.content.length > 0 ? (
                    course.content.map((data) => {
                      return (
                        <ul>
                          <li className="content-view-items">{data}</li>
                        </ul>
                      );
                    })
                  ) : (
                    <>
                      <Loader />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {sessionStorage.getItem("lang") === "Mar" ? (
          <Link
            to={{
              pathname: `/registerform`,
              state: {
                title: course.titleMr,
              },
            }}
          >
            <button className="view-course-buy-now">आता खरेदी करा</button>
          </Link>
        ) : (
          <Link
            to={{
              pathname: `/registerform`,
              state: {
                title: course.title,
              },
            }}
          >
            <button className="view-course-buy-now">Buy Now</button>
          </Link>
        )}
      </div>
      <Footer />
    </>
  );
};

export default CourseView;
