import React from "react";
import firebase from "firebase";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import "./EventView.css";
import { Link } from "react-router-dom";

// SEO HELMET
import { Seo } from "../seo/Seo";
import Loader from "../loader/Loader";

const EventView = () => {
  const db = firebase.firestore();
  const [event, setEvent] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    db.collection("upcomingEvents")
      .doc(id)
      .get()
      .then((snapshot) => {
        setEvent(snapshot.data());
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);
  let eventDate;
  if (event.date) {
    let date = event.date
      .toDate()
      .toDateString()
      .split(" ");
    eventDate = date[2] + " " + date[1] + " " + date[3];
  }
  return (
    <>
      {sessionStorage.getItem("lang") === "Mar" ? (
        <Seo
          title={event.coursesTitleMr}
          description={event.seoDescMr}
          keyword={event.seoKeywordMr}
          cardTitle={event.seoTitleMr}
          cardImage="/static/media/ChitralM.47070a5981c86494ad63.webp"
          cardDescription={event.seoDescMr}
          cardUrl="/event-View/:id"
          cardType="website"
        />
      ) : (
        <Seo
          title={event.courseTitle}
          description={event.seoDesc}
          keyword={event.seoKeyword}
          cardTitle={event.seoTitle}
          cardImage="/static/media/ChitralM.47070a5981c86494ad63.webp"
          cardDescription={event.seoDesc}
          cardUrl="/event-View/:id"
          cardType="website"
        />
      )}
      <div>
        <Navbar />
        {event ? (
          <div className="event-view-container">
            <div className="event-view-heading-container">
              <img
                src={event.image}
                alt="micIcon"
                className="event-view-image"
              ></img>
              {sessionStorage.getItem("lang") === "Mar" ? (
                <h3 className="event-view-heading">{event.coursesTitleMr}</h3>
              ) : (
                <h3 className="event-view-heading">{event.courseTitle}</h3>
              )}
              {sessionStorage.getItem("lang") === "Mar" ? (
                <p className="event-view-subheading">{event.subTitleMr}</p>
              ) : (
                <p className="event-view-subheading">{event.subTitle}</p>
              )}
            </div>
            <div className="event-view-mid-bottom-container">
              <div className="event-view-middle-section-container">
                <section className="event-view-left-section">
                  <div className="event-view-left-section-heading-container">
                    {sessionStorage.getItem("lang") === "Mar" ? (
                      <h3 className="event-view-left-section-heading">
                        यात असेल
                      </h3>
                    ) : (
                      <h3 className="event-view-left-section-heading">
                        This Includes
                      </h3>
                    )}
                  </div>

                  {sessionStorage.getItem("lang") === "Mar" ? (
                    event.WillbrMr ? (
                      <div className="event-view-left-section-list">
                        {event.WillbrMr.map((data) => {
                          return (
                            <>
                              <p className="event-view-left-section-items">
                                <i className="fas fa-circle event-view-list-styling"></i>

                                {data}
                              </p>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        <Loader />
                      </>
                    )
                  ) : event.willBe ? (
                    <div className="event-view-left-section-list">
                      {event.willBe.map((data) => {
                        return (
                          <>
                            <p className="event-view-left-section-items">
                              <i className="fas fa-circle event-view-list-styling"></i>

                              {data}
                            </p>
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    <>
                      <Loader />
                    </>
                  )}
                </section>

                <section className="event-view-right-section">
                  <div className="event-view-left-section-heading-container">
                    {sessionStorage.getItem("lang") === "Mar" ? (
                      <h3 className="event-view-left-section-heading">
                        हा कोर्स कोणासाठी?
                      </h3>
                    ) : (
                      <h3 className="event-view-left-section-heading">
                        Who is this Course for?
                      </h3>
                    )}
                  </div>
                  {sessionStorage.getItem("lang") === "Mar" ? (
                    event.courseForMr ? (
                      <div>
                        {event.courseForMr.map((data) => {
                          return (
                            <>
                              <p className="event-view-right-section-list">
                                <i className="fas fa-circle event-view-list-styling"></i>
                                {data}
                              </p>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        <Loader />
                      </>
                    )
                  ) : event.courseFor ? (
                    <div>
                      {event.courseFor.map((data) => {
                        return (
                          <>
                            <p className="event-view-right-section-list">
                              <i className="fas fa-circle event-view-list-styling"></i>
                              {data}
                            </p>
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    <>
                      <Loader />
                    </>
                  )}
                </section>
              </div>
              <div className="event-view-bottom-section-container">
                {sessionStorage.getItem("lang") === "Mar" ? (
                  <h6 className="event-view-course-start-date">
                    कोर्स सुरु होण्याची तारीख : {eventDate}
                  </h6>
                ) : (
                  <h6 className="event-view-course-start-date">
                    Course Start Date : {eventDate}
                  </h6>
                )}
                {sessionStorage.getItem("lang") === "Mar" ? (
                  <h6 className="event-view-course-duration">
                    कोर्स चा कालावधी :
                    <span className="event-view-course-duration-span">
                      {event.duration}
                    </span>
                  </h6>
                ) : (
                  <h6 className="event-view-course-duration">
                    Course Duration :
                    <span className="event-view-course-duration-span">
                      {event.duration}
                    </span>
                  </h6>
                )}
                {sessionStorage.getItem("lang") === "Mar" ? (
                  <p className="event-view-course-address">
                    कोर्सचे ठिकाण :
                    <span className="event-view-course-duration-span">
                      चित्रल वर्ल्ड , केके मार्केट,धनकवडी,पुणे
                    </span>
                  </p>
                ) : (
                  <p className="event-view-course-address">
                    Course Address :
                    <span className="event-view-course-duration-span">
                      {event.address}
                    </span>
                  </p>
                )}

                {sessionStorage.getItem("lang") === "Mar" ? (
                  <Link
                    to={{
                      pathname: `/registerform`,
                      state: {
                        title: event.coursesTitleMr,
                      },
                    }}
                  >
                    <button className="event-view-course-register-button">
                      रजिस्टर करा
                    </button>
                  </Link>
                ) : (
                  <Link
                    to={{
                      pathname: `/registerform`,
                      state: {
                        title: event.courseTitle,
                      },
                    }}
                  >
                    <button className="event-view-course-register-button">
                      Register Now
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <Loader />
          </>
        )}
        <Footer />
      </div>
    </>
  );
};

export default EventView;
