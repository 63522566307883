import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBLAYA0F5TOaEpcG1DYY1z590JXInZPcqA",
  authDomain: "chitralworld-d3338.firebaseapp.com",
  projectId: "chitralworld-d3338",
  storageBucket: "chitralworld-d3338.appspot.com",
  messagingSenderId: "476320990456",
  appId: "1:476320990456:web:b49d8bf5aa831ac97a270d",
  measurementId: "G-NDM3GXHPE3"
};

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();

export default firebase;
export {storage}