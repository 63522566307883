import React, { useState, useEffect } from "react";
import firebase from "../../firebase/Firebase";
import "./MentorsAndTrainers.css";

const MentorsAndTrainers = () => {
    const db = firebase.firestore();
    let cards;
    const [mentorsTrainers, setMentorsTrainers] = useState([]);
    useEffect(() => {
      getMentorsTrainers();
    }, []);

    const getMentorsTrainers = () => {
        db.collection("mentorsTrainers").onSnapshot((snapshot) => {
          setMentorsTrainers(snapshot.docs.map((doc) => doc.data()));
        });
      };

      console.log("mentorsTraienrs", mentorsTrainers);

      mentorsTrainers && mentorsTrainers.length > 0
    ? (cards = mentorsTrainers.map((data, index) => {
        console.log("TestImage", data.image);
        return (
          <div className="cards-card">
            <div className="card-img-container">
              <img className="card-image" src={data.image} alt="testImg" />
            </div>
            {sessionStorage.getItem("lang") === "Mar" ? (
              <p className="card-heading">{data.mentorsTrainersTitleMr}</p>
            ) : (
              <p className="card-heading">{data.mentorsTrainersTitle}</p>
            )}
            {sessionStorage.getItem("lang") === "Mar" ? (
              <p className="card-para">{data.mentorsTrainersDespMr}</p>
            ) : (
              <p className="card-para">{data.mentorsTrainersDesp}</p>
            )}
          </div>
        );
    }))
    : <></>

    return (
        <>  
          {mentorsTrainers && mentorsTrainers.length > 0 
          ? (<>
                <div className="trainers-mentors-container">
                <div className="trainers-mentors-heading-container">
                <h3 className="trainers-mentors-heading">Our Trainers/Mentors</h3>
                <hr className="trainers-mentors-heading-hr" />
                </div> 
                </div>
                <section className="trainer-mentor-container">
                {cards}
                </section>
            </>)
            : <></>
          }
        </>
    );
}

export default MentorsAndTrainers;