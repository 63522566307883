import React from "react";
import ChitralLogo from "../../assets/NavBar/chitralLogo.webp";
import ChitralLogoM from "../../assets/NavBar/ChitralM.webp";
import { Link, NavLink } from "react-router-dom";
import "./Navbar2.css";

const Navbar2 = () => {
  const setLangMar = () => {
    if (sessionStorage.getItem("lang") === "Mar") {
      sessionStorage.setItem("lang", "Eng");
      window.location.reload();
    } else {
      sessionStorage.setItem("lang", "Mar");
      window.location.reload();
    }
  };

  return (
    <>
      <div className="navbar-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light  p-1.5">
          {sessionStorage.getItem("lang") === "Mar" ? (
            <img className="navbar-logo2" src={ChitralLogoM} />
          ) : (
            <img className="navbar-logo2" src={ChitralLogo} />
          )}

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav navbar-items-container">
              <li className="nav-item active ">
                <NavLink
                  className={(isActive) =>
                    "navbar-items2 " +
                    (!isActive ? " unselected" : "is-active ")
                  }
                  to={`/`}
                >
                  Home <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(isActive) =>
                    "navbar-items2 " +
                    (!isActive ? " unselected" : "is-active ")
                  }
                  to={`/about-us`}
                >
                  About Us
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className={(isActive) =>
                    "navbar-items2 " +
                    (!isActive ? " unselected" : "is-active ")
                  }
                  to={`/event-Page`}
                >
                  Events
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(isActive) =>
                    "navbar-items2 " +
                    (!isActive ? " unselected" : "is-active ")
                  }
                  to={`/blogs`}
                >
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(isActive) =>
                    "navbar-items2 " +
                    (!isActive ? " unselected" : "is-active ")
                  }
                  to={`/courses-List`}
                >
                  Courses
                </NavLink>
              </li>
            </ul>

            <div className="navbar-language-and-btn-container">
              <div className="navbar-languages-wrapper">
                <i className="far fa-globe navbar-globe-icon"></i>
                <p className="navbar-items2" onClick={setLangMar}>
                  {sessionStorage.getItem("lang") === "Mar"
                    ? "English"
                    : "मराठीत पहा"}
                </p>
              </div>

              <div className="navbar2-last-btn-container">
                {sessionStorage.getItem("lang") === "Mar" ? (
                  <Link to={`/contact`}>
                    <button className="navbar2-last-btn">संपर्क साधा</button>
                  </Link>
                ) : (
                  <Link to={`/contact`}>
                    <button className="navbar2-last-btn">Contact Us</button>
                  </Link>
                )}
              </div>
            </div>

            <div></div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar2;
