import React, { useState, useEffect } from "react";
import Firebase from "../../firebase/Firebase";
import "./Comment.css";

const Comment = ({ id }) => {
  console.log("id", id);

  const [comment, setComment] = useState({
    email: "",
    name: "",
    comments: "",
    replies: [],
  });
  const [getComment, setGetComment] = useState([]);
  const [commentNested, setCommentNested] = useState("");
  const [openComments, setOpenComments] = useState(false);
  const [commentIndex, setCommentIndex] = useState(0);

  const db = Firebase.firestore();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    db.collection("blogs")
      .doc(id)
      .get()
      .then((snapshot) => {
        setGetComment(snapshot.data().comment);
      })
      .catch((err) => {
        console.log("Error", err);
      });  };

  const updateDb = (e) => {
    e.preventDefault();
    const comment1 = [...getComment, comment];
    db.collection("blogs")
      .doc(id)
      .update({
        comment: comment1,
      })
      .then(() => {
        console.log("updated");
        getData();
      })
      .catch((err) => {
        console.log("Error", err);
      });
    setComment({
      email: "",
      name: "",
      comments: "",
    });
  };

  const updateDbNested = (e, id1) => {
    e.preventDefault();
    let comment1 = getComment[id1];
    Object.assign(comment1, { replies: [...comment1.replies, commentNested] });

    comment1 = [...getComment, comment];

    db.collection("blogs")
      .doc(id)
      .update({
        comment: comment1,
      })
      .then(() => {
        console.log("updated");
        setOpenComments(false);

        getData();
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const openComment = (open, id) => {
    setOpenComments(open);
    setCommentIndex(id);
  };

  return (
    <>
      
      <div className="comments-page-container">
        <h3>Comment</h3>
        <form
          onSubmit={(e) => {
            updateDb(e);
          }}
        >
          <div>
            <div className="main-comment-input-container">
              <input
                className="main-comment-input"
                type="text"
                required
                placeholder="Name"
                value={comment.name}
                onChange={(e) =>
                  setComment({ ...comment, name: e.target.value })
                }
              />
              <input
                className="main-comment-input1"
                required
                placeholder="EmailID"
                type="email"
                value={comment.email}
                onChange={(e) =>
                  setComment({ ...comment, email: e.target.value })
                }
              />
            </div>

            <textarea
              className="comment-box"
              cols="5"
              rows="4"
              placeholder="comment here"
              value={comment.comments}
              onChange={(e) =>
                setComment({ ...comment, comments: e.target.value })
              }
            />
          </div>
          <button
            type="submit"
            disabled={!comment.comments}
            className="comment-add-btn"
          >
            Add comment
          </button>
        </form>

        <div className="repiles-comments">
          <div className="all-comments-container">
            {getComment ? (
              getComment.map((data, id) => {
                return (
                  <div>
                    <div className="repiles-comments-inner-container">
                      <div className="repiles-comments-name-box">
                        {data.name ? (
                          <p className="repiles-comments-avtar">
                            {data.name.slice(0, 2)}
                          </p>
                        ) : (
                          <p className="repiles-comments-avtar">C</p>
                        )}
                        <div className="repiles-comments-name-email-cont">
                          <p className="repiles-comments-name">{data.name}</p>
                        </div>
                      </div>
                      <p className="user-repiles-comments">{data.comments}</p>
                      <div className="main-reply-container">
                        <i className="far fa-reply main-reply-arrow"></i>
                        <button
                          className="user-reply-comments-btn"
                          onClick={() => {
                            openComment(!openComments, id);
                          }}
                        >
                          reply
                        </button>
                      </div>
                    </div>

                    {openComments === true && commentIndex === id ? (
                      <div className="second-reply-container">
                        <form onSubmit={(e) => updateDbNested(e, id)}>
                          <div className="second-reply-input-container">
                            <input
                              type="text"
                              placeholder="Name"
                              value={commentNested.name}
                              required
                              onChange={(e) =>
                                setCommentNested({
                                  ...commentNested,
                                  name: e.target.value,
                                })
                              }
                              className="second-reply-input"
                            />
                            <input
                              required
                              placeholder="EmailID"
                              type="email"
                              value={commentNested.email}
                              onChange={(e) =>
                                setCommentNested({
                                  ...commentNested,
                                  email: e.target.value,
                                })
                              }
                              className="second-reply-input"
                            />
                          </div>
                          <textarea
                            required
                            className="second-comment-box"
                            cols="5"
                            rows="4"
                            placeholder="Comment here"
                            value={commentNested.comment}
                            onChange={(e) =>
                              setCommentNested({
                                ...commentNested,
                                comment: e.target.value,
                              })
                            }
                          />
                          <button className="second-comment-btn" type="submit">
                            Add comment
                          </button>
                        </form>
                      </div>
                    ) : (
                      <></>
                    )}

                    {data.replies ? (
                      data.replies.map((rep) => {
                        return (
                          <div className="second-repiles-inner-container">
                            <div className="repiles-comments-name-box">
                              <p className="repiles-comments-avtar1">
                                {rep.name.slice(0, 2)}
                              </p>
                              <div className="repiles-comments-name-email-cont">
                                <p className="repiles-comments-name">
                                  {rep.name}
                                </p>
                              </div>
                            </div>
                            <p className="user-repiles-comments">
                              {rep.comment}
                            </p>
                            <div className="main-reply-container">
                              <i className="far fa-reply main-reply-arrow"></i>
                              <button
                                className="user-reply-comments-btn"
                                onClick={() => {
                                  openComment(!openComments, id);
                                }}
                              >
                                reply
                              </button>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Comment;
